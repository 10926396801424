import { useState, useEffect } from 'react';
import './Home.css';
import { checkSession } from '../../utils/utils';
import { Helmet } from 'react-helmet';

import BuyerLogoMap from './BuyerLogoMap';
import CardMap from './CardMap';
import HeroLeft from './HeroLeft';
import HeroRight from './HeroRight';
import PayoutLogoMap from './PayoutLogoMap';
import HowItWorks from './HowItWorks';
import Testimonial from './Testimonial';
import SellerLogoMap from './SellerLogoMap';
import HeroVendor from './HeroVendor';
import HeroApp from './HeroApp';
import ArticleMap from './ArticleMap';
import TopHero from './TopHero';
import TopHeroMobile from './TopHeroMobile';
import BuyerLogoMapMobile from './BuyerLogoMapMobile';
import CardMapMobile from './CardMapMobile';
import HeroAppMobile from './HeroAppMobile';
import HeroLeftMobile from './HeroLeftMobile';
import HeroRightMobile from './HeroRightMobile';
import PayoutLogoMapMobile from './PayoutLogoMapMobile';
import HowItWorksMobile from './HowItWorksMobile';
import TestimonialMobile from './TestimonialMobile';
import ArticleMapMobile from './ArticleMapMobile';
import HeroVendorMobile from './HeroVendorMobile';

const Home4 = () => {
  // Initialize a state variable to track the screen width
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Add an event listener to update the windowWidth when the window is resized
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    checkSession('Home Page');

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Define a breakpoint value for switching between mobile and desktop views
  const breakpoint = 1000; // Adjust this value as needed

  return (
    <>
    <Helmet>
      <title>Discover and learn about the latest software products</title>
      <link rel="canonical" href={`https://www.leadrpro.com`} />
      <meta name="description" content="Discover the latest in innovative software. LeadrPro showcases some of the most innovative software solutions out on the market today."></meta>
      <meta name="keywords" content="software marketplace, saas, paid meetings, demos, sales"></meta>

    </Helmet>
      {windowWidth < breakpoint ? <TopHeroMobile /> : <TopHero />}
      {windowWidth < breakpoint ? <BuyerLogoMapMobile /> : <BuyerLogoMap />}
      {windowWidth < breakpoint ? <CardMapMobile /> : <CardMap />}
      {windowWidth < breakpoint ? <HeroAppMobile /> : <HeroApp />}
      {/* {windowWidth < breakpoint ? <HeroApp /> : <HeroApp />} */}

      {windowWidth < breakpoint ? <HeroLeftMobile /> : <HeroLeft />}
      {windowWidth < breakpoint ? <HeroRightMobile /> : <HeroRight />}
      {windowWidth < breakpoint ? <PayoutLogoMapMobile /> : <PayoutLogoMap />}
      {windowWidth < breakpoint ? <HowItWorksMobile /> : <HowItWorks />}
      {windowWidth < breakpoint ? <TestimonialMobile /> : <Testimonial />}
      {windowWidth < breakpoint ? '' : <HeroVendor />}
      {windowWidth < breakpoint ? <ArticleMapMobile /> : <ArticleMap />}
      {/* <SellerLogoMap /> */}
    </>
  );
};

export default Home4;
