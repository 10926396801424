import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminSearchUsers } from '../../utils/api';
import { adminPayouts, updatePayoutStatus, updateCompanyProfile } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaEnvelope, FaGlobe, FaRegEnvelope } from 'react-icons/fa';
import MarkdownEditor from './Components/MarkdownEditor';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';

function PortfolioCompaniesEdit( {id, item, setShow, loadData }) {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [payouts, setPayouts] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [countPending, setCountPending] = useState(0);
  const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

    // Add state for new fields
    const [companyName, setCompanyName] = useState('');
    const [companyWebsite, setCompanyWebsite] = useState('');
    const [companyHq, setCompanyHq] = useState('');
    const [companyAddress, setCompanyAddress] = useState('');
    const [yearFunded, setYearFunded] = useState('');
    const [lastFundedDate, setLastFundedDate] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [series, setSeries] = useState('');
    const [companyImage, setCompanyImage] = useState(null);
    const [companyLogo, setCompanyLogo] = useState(null);
    const [fundingRounds, setFundingRounds] = useState('');
    const [keyInvestors, setKeyInvestors] = useState('');
    const [keyMembers, setKeyMembers] = useState('');
    const [contentDescription, setContentDescription] = useState('');

  useEffect(() => {
    async function loadItem(){
      setCompanyName(item.name);
      setCompanyWebsite(item.website);
      setCompanyHq(item.headquarters);
      setCompanyAddress(item.company_address);
      setCompanyImage(item.company_image);
      setCompanyLogo(item.company_logo);
      setYearFunded(item.year_founded);
      setLastFundedDate(item.last_funded_date);
      setShortDescription(item.short_description);
      setDescription(item.description);
      setSeries(item.series);
      setFundingRounds(item.funding_rounds);
      setKeyInvestors(item.key_investors);
      setKeyMembers(item.key_members);

    }

    loadItem();
  }, []);

  async function loadItem(){
    let res = await adminPayouts(200);

    if (res.success){
      setPayouts(res.data);
      setLoaded(true);
    }

    else{
      alert('failed to reload');
    }
  }

  async function handleAdd(){
    let res = await updateCompanyProfile({
      name: companyName,
      website: companyWebsite,
      headquarters: companyHq,
      company_address: companyAddress,
      year_founded: yearFunded,
      last_funded_date: lastFundedDate,
      short_description: shortDescription,
      description: contentDescription,
      series,
      company_image: companyImage,
      company_logo: companyLogo,
      funding_rounds: fundingRounds,
      key_investors: keyInvestors,
      key_members: keyMembers}, id);

      if (res.success){
        await loadData();
        setShow(false);
       // alert('Company updated successfully');
      }
  }

  async function onImageSuccess(e){
    setCompanyImage(e.name);
  }

  async function onImageError(){
  }

  async function onLogoSuccess(e){
    setCompanyLogo(e.name);
  }

  async function onLogoError(){
  }

  return (
    <>
      <Helmet>
        <title>Admin - Payouts</title>
      </Helmet>

      <h1 className='mb-4'>Portfolio Companies Edit</h1>

      {/* Add input controls */}
      <div className="form-section mb-4">
        <label className="font-bold">Company Name</label>
        <input
          className="input"
          type="text"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Company Website</label>
        <input
          className="input"
          type="url"
          value={companyWebsite}
          onChange={(e) => setCompanyWebsite(e.target.value)}
        />
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Company Address</label>
        <input
          className="input"
          type="text"
          value={companyAddress}
          onChange={(e) => setCompanyAddress(e.target.value)}
        />
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Year Funded</label>
        <input
          className="input"
          type="number"
          value={yearFunded}
          onChange={(e) => setYearFunded(e.target.value)}
        />
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Last Funded Date</label>
        <input
          className="input"
          type="date"
          value={lastFundedDate}
          onChange={(e) => setLastFundedDate(e.target.value)}
        />
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Short Description</label>
        <input
          className="input"
          type="text"
          value={shortDescription}
          onChange={(e) => setShortDescription(e.target.value)}
        />
      </div>

      <div className="form-section mb-4">
      <label className="font-bold">Company Headquarters</label>
      <div>
          <MarkdownEditor content={companyHq} onChange={setCompanyHq} />
        </div>
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Description</label>
        <div className="mb-4">
          <MarkdownEditor content={contentDescription} onChange={setContentDescription} />
        </div>
        <textarea
          className="input"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Series</label>
        <select
          className="input"
          value={series}
          onChange={(e) => setSeries(e.target.value)}
        >
          <option value="">Select Series</option>
          <option value="Pre-Seed">Pre-Seed</option>
          <option value="Seed">Seed</option>
          <option value="Series A">Series A</option>
          <option value="Series B">Series B</option>
          <option value="Series C">Series C</option>
          <option value="Series D">Series D</option>
          <option value="IPO">IPO</option>
          <option value="Other">Other</option>
        </select>
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Company Logo</label>
        
         {companyLogo && companyLogo !== '' && (
                      <div className="companyedit__logo-image">
                        <img src={imageUrl + 'company_profiles/' + companyLogo} alt="logo found" />
                      </div>
                    )}
        
                      <div style={{display: "flex"}}>
                        <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                        <IKUpload
                          fileName="logo.jpg"
                          tags={["sample-tag1", "sample-tag2"]}
                          customCoordinates={"10,10,10,10"}
                          isPrivateFile={false}
                          useUniqueFileName={true}
                          responseFields={["tags"]}
                          folder={"/company_profiles"}
                          onError={onLogoError} onSuccess={onLogoSuccess}
                        />
                        </IKContext>
                      </div>
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Company Image</label>
        
         {companyImage && companyImage !== '' && (
                      <div className="companyedit__logo-image">
                        <img src={imageUrl + 'company_profiles/' + companyImage} alt="logo found" />
                      </div>
                    )}
        
                      <div style={{display: "flex"}}>
                        <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                        <IKUpload
                          fileName="logo.jpg"
                          tags={["sample-tag1", "sample-tag2"]}
                          customCoordinates={"10,10,10,10"}
                          isPrivateFile={false}
                          useUniqueFileName={true}
                          responseFields={["tags"]}
                          folder={"/company_profiles"}
                          onError={onImageError} onSuccess={onImageSuccess}
                        />
                        </IKContext>
                      </div>
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Funding Rounds</label>

        <div>
          <MarkdownEditor content={fundingRounds} onChange={setFundingRounds} />
        </div>
      
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Key Investors</label>

        <div>
          <MarkdownEditor content={keyInvestors} onChange={setKeyInvestors} />
        </div>
      </div>

      <div className="form-section mb-4">
        <label className="font-bold">Key Members</label>

        <div>
          <MarkdownEditor content={keyMembers} onChange={setKeyMembers} />
        </div>
      </div>

      <div className='mt-4'>
        <button className='btn' onClick={() => handleAdd()}>Add Company</button>
      </div>

    </>
  );
}

export default PortfolioCompaniesEdit;