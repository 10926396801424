import React from 'react';
import { Box, Flex, Image, Center, Text, Spacer } from '@chakra-ui/react';

const PayoutLogoMap = () => {
  return (
    <>
      <Flex
        mt='120px'
        direction={'column'}
        boxSize='full'
        bg='white'
        p={10}
        alignItems='center'
        justifyContent='center'>
        <Box>
          <Center>
            <Text
              textTransform='uppercase'
              fontWeight='semibold'
              mb={10}
              fontFamily='poppins'
              fontSize={['lg', , '40px']}
              textAlign='center'>
              Get paid easily via
            </Text>
          </Center>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          w='full'
          alignItems='center'
          justifyContent='center'
          // px={20}
          mb={10}
          px='200px'>
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/paypal-logo.png?updatedAt=1697157558239'
            alt='paypal'
            maxW='100px'
          />

          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/venmo-logo.png?updatedAt=1697157558358'
            alt='venmo'
            maxW='100px'
          />

          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/cashapp-logo.png?updatedAt=1697157558311'
            alt='cashapp'
            maxW='100px'
            py={{ base: 4, md: 0 }}
          />

          <Spacer />
          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/zelle-logo.png?updatedAt=1697157558389'
            alt='zelle'
            maxW='90px'
            pb={{ base: 4, md: 0 }}
          />

          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/amazon.jpeg?updatedAt=1697158089851'
            alt='amazon'
            maxW='100px'
          />

          <Spacer />

          <Image
            src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/visa-logo.png?updatedAt=1697157558593'
            alt='visa'
            maxW='100px'
          />
        </Flex>
      </Flex>
    </>
  );
};

export default PayoutLogoMap;
