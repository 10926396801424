import React from 'react';
import {
  Box,
  Flex,
  Image,
  Center,
  Text,
  Spacer,
  VStack,
} from '@chakra-ui/react';

const PayoutLogoMapMobile = () => {
  return (
    <>
      <Flex
        mt='50px'
        mb='40px'
        direction={'column'}
        boxSize='full'
        bg='white'
        p={10}
        alignItems='center'
        justifyContent='center'>
        <Box>
          <Center>
            <Text
              textTransform='uppercase'
              fontWeight='semibold'
              mb={10}
              fontFamily='poppins'
              fontSize='30px'
              textAlign='center'>
              Get paid easily via
            </Text>
          </Center>
        </Box>

        <Flex
          direction={{ base: 'column', md: 'row' }}
          w='full'
          alignItems='center'
          justifyContent='center'
          px='200px'>
          <VStack spacing='20px'>
            <Image
              src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/paypal-logo.png?updatedAt=1697157558239'
              alt='paypal'
              maxW='200px'
            />

            <Spacer />

            <Image
              src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/venmo-logo.png?updatedAt=1697157558358'
              alt='venmo'
              maxW='200px'
            />

            <Spacer />

            <Image
              src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/cashapp-logo.png?updatedAt=1697157558311'
              alt='cashapp'
              maxW='200px'
            />

            <Spacer />
            <Image
              src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/zelle-logo.png?updatedAt=1697157558389'
              alt='zelle'
              maxW='150px'
            />

            <Spacer />

            <Image
              src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/amazon.jpeg?updatedAt=1697158089851'
              alt='amazon'
              maxW='200px'
            />

            <Spacer />

            <Image
              src='https://ik.imagekit.io/slk77uytr/LeadrPro/Payout%20Logos/visa-logo.png?updatedAt=1697157558593'
              alt='visa'
              maxW='150px'
            />
          </VStack>
        </Flex>
      </Flex>
    </>
  );
};

export default PayoutLogoMapMobile;
