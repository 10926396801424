import { useEffect, useState } from 'react';
import './DashboardDemo.css';
import DeclinedPopup from './DeclinedPopup';
import { Link } from 'react-router-dom';
import QualifierQuestions from '../../components/qualifier/QualifierQuestions';
import AvailableTimes from '../../components/qualifier/AvailableTimes';
import { getSeoName } from '../../utils/utils';
import { GetBuyerAvailableRecommendations, getUserProfile, createSellerDemo, uet, addCompanyClick,hasAvailableApplications } from '../../utils/api';
import { refresh, isAdmin } from '../../services/auth.service';
import { getTrimmedString } from '../../utils/utils';
import { Helmet } from 'react-helmet';
import { FaTimes, FaStar, FaRegStar, FaAngleDoubleRight, FaRegCheckCircle, FaDatabase, FaBolt } from 'react-icons/fa';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import imgCoin from '../../assets/rewards/lp-coin.png';


const BuyerDemosAvailable = ({ demos, setDemos, buyerDeclinedReasons, refreshPoints }) => {
  const [showCompanyInfo, setShowCompanyInfo] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [currentDemoId, setCurrentDemoId] = useState(0);
  const [showQualifier, setShowQualifier] = useState(false);
  const [showDeclinedPopup, setShowDeclinedPopup] = useState(false);
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [hasDemos, setHasDemos] = useState(true);
  const [buyerRecommendations, setBuyerRecommendations] = useState([]);
  const [recommendationsLoaded, setRecommendationsLoaded] = useState(false);
  const [selectedRecommendation, setSelectedRecommendation] = useState(null);
  const [showRecommended, setShowRecommended] = useState(false);
  const [useProfile, setUserProfile] = useState(null);
  const [profileLoaded, setProfileLoaded] = useState(false);
  const [workEmailVerified, setWorkEmailVerified] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [numberShowing, setNumberShowing] = useState(12);
  const [appliedTo, setAppliedTo] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [version, setVersion] = useState('A');
  const [showMaxReached, setShowMaxReached] = useState(false);
  const [hasAvailable, setHasAvailable] = useState(true);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;

  useEffect(() => {
    async function loadVersion(){
      // check local storage for active version
      // if not found, pick one with 50/50 split
      // set in local storage
      let ls = localStorage.getItem('buyer_rec_version');

      if (ls == null){
        const assignedVersion = Math.random() < 0.5 ? 'A' : 'B';
        setVersion(assignedVersion);
        localStorage.setItem('buyer_rec_version', assignedVersion);
      }

      else{
        setVersion(ls);
      }
    }

    async function loadBuyerRecommendations(){
      let res = await GetBuyerAvailableRecommendations();

      if (res.success){
        setBuyerRecommendations(res.data);
        setRecommendationsLoaded(true);
      }
    }

    async function loadUserProfile() {
      let res = await getUserProfile();

      if (res.success) {
        setProfileLoaded(true);
        setUserProfile(res.data);
        setWorkEmailVerified(res.data.work_email_verified);
      } else if (res.status === 403) {
          await refresh();
          let res = await getUserProfile();

          if (res.success) {
            setProfileLoaded(true);
            setUserProfile(res.data);
            setWorkEmailVerified(res.data.work_email_verified);
          }
      }
    }

    loadVersion();
    loadUserProfile();
    loadBuyerRecommendations();
  }, []);

  useEffect(() => {
    if (showConfirmation == true){
      refreshPoints();
    }
  }, [showConfirmation]);

  async function handleQualifierClick(demo_id, item, version = 1){
    let _check = await hasAvailableApplications();

    if (_check.success){
      if (_check.data === true){
        setShowMaxReached(false);
        setHasAvailable(true);
      }

      else{
        setHasAvailable(false);
        setShowMaxReached(true);
        //setBookingLoading(false);
        return;
      }
    }

    setCurrentDemo(item);
    setSubmitting(true);

    let data = {seller_id: item.seller_id};
    let _res = await createSellerDemo(data);

    if (_res.success){
      setCurrentDemoId(_res.data);
      setShowQualifier(true);
      setSubmitting(false);
    }

    else{
      setCurrentDemoId(_res.demo_id);
      setShowQualifier(true);
      setSubmitting(false);
    }

    await uet('Dashboard buyer recommendation', item.company_id);

    if (version == 1)
      await addCompanyClick(item.company_id, 'Dashboard recommendations (A version)');

    else if (version == 2){
      await addCompanyClick(item.company_id, 'Dashboard recommendation (B version)');
    }
  }

  function demoAccepted(){
    let _filter = buyerRecommendations.filter(item => item.company_id !== currentDemo.company_id);
    setBuyerRecommendations(_filter);
  }

  async function handleBook(item){
    setSelectedRecommendation(item);
    setShowRecommended(true);
  }

  function getStars(number){
    let mainindex = 0;
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++){
      el.push(<span key={mainindex} className="review__star" style={{fontSize: '14px'}}><FaStar /></span>);
      mainindex++;
    }

    for (let i = 0; i < 5 - number; i++){
      el.push(<span key={mainindex} className="review__star--unselected" style={{fontSize: '14px'}}><FaRegStar /></span>);
      mainindex++;
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  async function handleLoadMore(){
    setNumberShowing(prev => prev + 7);
  }

  return (
    <>
    <Helmet>
      <title></title>
    </Helmet>

    {(hasDemos === false || hasDemos === true) && (
      <>
        <div className='mt-10' style={{fontSize: '24px', fontFamily: 'poppins', fontWeight: 'bold'}}>
          Available Demos
        </div>

        <div style={{fontSize: '20px', color: '#444'}} className='mb-20'>
          Book demos. Find solutions and earn rewards.
        </div>

        <div className='buyerdashboard__recommendations'>
          {recommendationsLoaded === false && (
            <>
              <div className='buyerdashboard__recommendation' style={{background: '#eee', height: '150px'}}>
              </div>

              <div className='buyerdashboard__recommendation' style={{background: '#eee', height: '150px'}}>
            </div>

            <div className='buyerdashboard__recommendation' style={{background: '#eee', height: '150px'}}>
            </div>

            <div className='buyerdashboard__recommendation' style={{background: '#eee', height: '150px'}}>
            </div>

            <div className='buyerdashboard__recommendation' style={{background: '#eee', height: '150px'}}>
            </div>
            </>
          )}

      {recommendationsLoaded && (isAdmin() || version == 'B') && (
          <div>
            {buyerRecommendations.slice(0, numberShowing).map((item, index) => (
              <div className='buyerdashboard__recommendation' key={index}>
                <div className='buyerdashboard__recommendation-thumbnail'>
                {item.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                  )}
                </div>
                
                <div className='buyerdashboard__recommendation-name'>
                <div className='flex buyerdashboard__recommendation-logo-wrapper'>
                    <div>
                      <div className='buyerdashboard__recommendation-company-logo'>
                          <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                        </div>
                    </div>

                    <div className='ml-10'>
                      <div className='buyerdashboard__recommendation-logo-name'>
                       {item.name}
                      </div>

                      <div style={{lineHeight: '0', marginTop: '5px'}}>
                        <div className="company__review">
                          {item.average_rating && (
                            <div className="flex">
                                {getStars(Math.ceil(item.average_rating))}

                                <div className='ml-5' style={{lineHeight: '0'}}>
                                  <span className="" style={{fontWeight: 'bold', fontSize: '12px'}}>{Math.round(item.average_rating * 10) / 10} out of 5</span>
                                </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='buyerdashboard__recommendation-tagline'>{item.tagline}</div>

                  <div className='buyerdashboard__recommendation-tags'>
                    <div className='buyerdashboard__recommendation-tag'>
                      <span className='va-m'><svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 3.4C15 4.72548 11.866 5.8 8 5.8C4.13401 5.8 1 4.72548 1 3.4M15 3.4C15 2.07452 11.866 1 8 1C4.13401 1 1 2.07452 1 3.4M15 3.4V14.6C15 15.928 11.8889 17 8 17C4.11111 17 1 15.928 1 14.6V3.4M15 9C15 10.328 11.8889 11.4 8 11.4C4.11111 11.4 1 10.328 1 9" stroke="#19202C" stroke-opacity="0.6" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
                      <span className='ml-5'>{item.industry}</span>
                    </div>

                    {item.is_new == true && (
                    <div className='buyerdashboard__recommendation-tag purple ml-10'>
                      <span className='va-m'><svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.77778 1L1 10.6H8L7.22222 17L15 7.4H8L8.77778 1Z" stroke="#8B52FF" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
                      <span className='ml-5'>NEW</span>
                    </div>
                    )}

                    {item.is_featured && (
                      <div className='buyerdashboard__recommendation-tag featured ml-10'>
                        <span className='va-m'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="18" viewBox="0 0 576 512"><path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/></svg>
                        </span>
                        
                        <span className='ml-5'>Featured</span>
                      </div>
                    )}
                  </div>

                  <div className='buyerdashboard__recommendation-description'>
                    {item.description && item.description.length > 0 && (
                      <>
                    {getTrimmedString(item.description)} <Link className='link' to={`/company/${item.company_id}/${item.url_slug}`}>Read More</Link>
                    </>
                    )}
                  </div>

                </div>

                <div className='buyerdashboard__recommendation-cta buyerdashboard__recommendation-cta--flex'>
                  <div>
                    <div className='buyerdashboard__recommendation-cta-earn'>
                      Earn <span className='buyerdashboard__recommendation-cta-dollar'>$75</span>
                    </div>

                    <div className='mt-5 buyerdashboard__recommendation-cta-time'>
                        <span className='va-m'><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 3.27054V6.67635L9.4 7.81162M13 6.67635C13 9.81131 10.3137 12.3527 7 12.3527C3.68629 12.3527 1 9.81131 1 6.67635C1 3.54139 3.68629 1 7 1C10.3137 1 13 3.54139 13 6.67635Z" stroke="#19202C" stroke-opacity="0.6" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</span>
                      <span className='ml-5'>30-45 minutes</span>
                    </div>
                  </div>

              {workEmailVerified && (
                <>
                {(submitting === false || currentDemo.company_id !== item.company_id) && (
                <button className='buyerdashboard__recommendation-cta-btn' onClick={() => handleQualifierClick(item.demo_id, item, 2)}>
                  <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <span className='va-m'>Book Demo</span>
                  </div>
                </button>
                )}

                {submitting && currentDemo.company_id === item.company_id && (
                  <div className='center'>
                    <Spinner />
                  </div>
                )}
                </>
              )}

              {workEmailVerified === false && (
                <button className='btn btn--book' onClick={() => handleBook(item)}>
                  <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <span className='va-m'>Get Started</span>
                    <span className='va-m'><FaAngleDoubleRight /></span>
                  </div>
                </button>
              )}
            </div>
          </div>
            ))}
          </div>
          )}

          {recommendationsLoaded && version == 'A' && (
          <div>
            {buyerRecommendations.slice(0, numberShowing).map((item, index) => (
              <div className='buyerdashboard__recommendation' key={index}>
                <div className='buyerdashboard__recommendation-thumbnail'>
                {item.thumbnail_image && (
                  <img src={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + item.thumbnail_image} alt={`${item.name} thumbnail`} />
                  )}
                </div>
                
                <div className='buyerdashboard__recommendation-name'>
                  <div>{item.name}</div>

                  <div className='buyerdashboard__recommendation-description'>
                    {item.description && item.description.length > 0 && (
                      <>
                    {getTrimmedString(item.description)}
                    </>
                    )}
                  </div>
                </div>

                <div className='buyerdashboard__recommendation-cta'>
                  <div>
                      <div className='buyerdashboard__recommendation-cta-earn'>
                        Earn <span className='buyerdashboard__recommendation-cta-dollar'>$75</span>
                      </div>

                      <div className='mt-5 buyerdashboard__recommendation-cta-time' style={{textAlign:'right'}}>
                          <span className='va-m'><svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7 3.27054V6.67635L9.4 7.81162M13 6.67635C13 9.81131 10.3137 12.3527 7 12.3527C3.68629 12.3527 1 9.81131 1 6.67635C1 3.54139 3.68629 1 7 1C10.3137 1 13 3.54139 13 6.67635Z" stroke="#19202C" stroke-opacity="0.6" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  </span>
                        <span className='ml-5'>30-45 minutes</span>
                      </div>
                    </div>

                {/* <div className="company__review">
                {item.average_rating && (
                  <div className="">
                      {getStars(Math.ceil(item.average_rating))}

                      <div className='mb-20'>
                        <span className="" style={{fontWeight: 'bold', fontSize: '12px'}}>{Math.round(item.average_rating * 10) / 10} out of 5</span>
                      </div>
                  </div>
                )}
              </div> */}

              {workEmailVerified && (
                <>
                
                <div className='mt-20' style={{textAlign: 'right'}}>
                  {(submitting === false || currentDemo.company_id !== item.company_id) && (
                  <button className='buyerdashboard__recommendation-cta-btn' onClick={() => handleQualifierClick(item.demo_id, item)}>
                    <div style={{display: 'flex', justifyContent: 'space-around'}}>
                      <span className='va-m'>Book Demo</span>
                    </div>
                  </button>
                  )}

                  {submitting && currentDemo.company_id === item.company_id && (
                    <div className='center'>
                      <Spinner />
                    </div>
                  )}
                </div>

                <div className='mt-10 center'>
                  <Link className='buyerdashboard__recommendation-cta-btn' to={`/company/${item.company_id}/${item.url_slug}`}>
                    Company Profile
                  </Link>
                </div>
                </>
              )}

              {workEmailVerified === false && (
                <div className='mt-10'>
                <button className='buyerdashboard__recommendation-cta-btn' onClick={() => handleBook(item)}>
                  <div style={{display: 'flex', justifyContent: 'space-around'}}>
                    <span className='va-m'>Apply for demo</span>
                    <span className='va-m'><FaAngleDoubleRight /></span>
                  </div>
                </button>
                </div>
              )}
            </div>
          </div>
            ))}
          </div>
          )}
        </div>

        <div className='center mt-30'>
          <button className='btn' onClick={() => handleLoadMore()}>Load more</button>
        </div>

        {/* <div className='center mt-30'>
          <Link to='/software' className='btn btn--primary'>View all software</Link>.
        </div> */}
      </>
    )}
{/* 
        {demos.filter((demo) => (demo.buyer_status === 'Pending') && demo.demo_status === 'Pending').map((item, index) => (
            <div className='' key={index}>
              <div className='dashboarddemo'>
                  <div className='dashboarddemo__company-logo'>
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.logo} />
                  </div>

                  <div className='dashboarddemo__company-info'>
                    <div className='dashboarddemo__company-name'>
                      {item.company_name}
                    </div>

                    {item.description && (
                      <div className='dashboarddemo__company-description'>
                        <div className='dashboarddemo__company-description-text'>
                          {trimCompanyInfo(item.description)}
                        </div>
                      </div>
                    )}

                    {item.description && item.description.length > 0 && (
                      <div className='dashboarddemo__more-link'>
                        <Link className='link__dash' to={`/company/${item.company_id}`}>
                          More about this company
                        </Link>
                      </div>
                    )}
                  </div>

                  <div className='dashboarddemo__earn'>
                    <div className='dashboarddemo__earn-header'>
                      Earn
                    </div>

                    <div className='dashboarddemo__earn-amount'>
                      ${item.credit_cost * item.credits}
                    </div>

                    <div className='dashboarddemo__earn-minutes'>
                      30 minute demo
                    </div>
                  </div>

                  <div className='dashboarddemo__actions'>
                    {item.seller_status === 'Approved' && (
                      <>
                      {item.has_questions && (
                        <button className='btn btn--primary btn--apply' onClick={() => handleQualifierClick(item.demo_id, item)}>Confirm</button>
                      )}

                      {!item.has_questions && (
                        <button className='btn btn--primary btn--apply' onClick={() => acceptClickHandle(item, item.demo_id)}>
                          Apply
                        </button>
                      )}

                      <div className='dashboarddemo__actions-btn'>
                        <button className='btn btn--cancel' onClick={() => declinedClickHandle(item, item.demo_id)}>Not interested</button>
                      </div>
                      </>
                    )}

                    {item.buyer_status === 'Approved' && (
                      <>
                        <div className='dashboarddemo__actions-text'>
                          Demo request was sent
                        </div>
                      </>
                    )}

                    {item.buyer_status === 'Pending' && item.seller_status === 'Pending' && (
                      <>
                        {item.has_questions && (
                        <div className='dashboarddemo__actions-btn'>
                          <button className='btn btn--primary btn--apply' onClick={() => handleQualifierClick(item.demo_id, item)}>Interested?</button>
                        </div>
                        )}

                        {!item.has_questions && (
                          <button className='btn btn--primary btn--apply' onClick={() => acceptClickHandle(item, item.demo_id)}>
                            Apply
                          </button>
                        )}

                        <div className='dashboarddemo__actions-btn'>
                          <button className='btn btn--primary btn--cancel-demo' onClick={() => declinedClickHandle(item, item.demo_id)}>Not interested</button>
                        </div>
                      </>
                    )}
                  </div>
              </div>
            </div>
          ))} */}

      {showDeclinedPopup &&
      (
        <div>
          <DeclinedPopup
            showDeclinedPopup={showDeclinedPopup}
            setShowDeclinedPopup={setShowDeclinedPopup}
            role='Buyer'
            buyerDeclinedReasons={buyerDeclinedReasons}
            currentDemo={currentDemo}
            demos={demos}
            setDemos={setDemos}
            source='Decline'
          />
        </div>
      )}

      {showQualifier && (
        <FadeIn delay={300} duration={300}>
          <div className='overlay'></div>
          <div className='modal modal--pad0 modal--50'>
            <QualifierQuestions currentDemo={currentDemo} demo_id={currentDemoId} setShowQualifier={setShowQualifier} setShowSelectedTime={setShowSelectedTime}></QualifierQuestions>
          </div>
        </FadeIn>
      )}

      {showSelectedTime && (
        <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              <AvailableTimes demo={currentDemo} demo_id={currentDemoId} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
            </div>
        </div>
      )}

      {showCompanyInfo && (
        <>
          <div className='overlay'></div>
          <div className='modal' style={{width: "80%", marginLeft: "-40%", maxHeight: "80%"}}>
            <div className='modal__close' onClick={() => setShowCompanyInfo(false)}>
              <FaTimes />
            </div>

            <div className='modal__header'>
              Company Info
            </div>

            <div className=''>
              {currentDemo.description}
            </div>

            <div className='' style={{marginTop: "40px"}}>
              <a className='link' href={'/company/' + currentDemo.company_id + '/' + getSeoName(currentDemo.company_name)}>See full company profile</a>
            </div>
          </div>
        </>
      )}

    {showConfirmation && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <div className='modal__close' onClick={() => setShowConfirmation(false)}>
          <FaTimes />
        </div>
        
        {/* <div className='center' style={{fontSize: "50px", marginBottom: "20px", color: 'mediumseagreen'}}>
          <FaRegCheckCircle />
        </div> */}

        <div className='buyerdashboard__plus-points'>
          <span><img src={imgCoin} alt='' /></span>
          <strong className='ml-10'>+5</strong>
        </div>

        <div className='modal__h2 center'>Success!</div>

        <p className='mt-20'>
          Your request has been submitted and will be reviewed soon. You should hear back within 24 hours.
        </p>

        <div className='center mt-20'>
          <button className='btn btn--primary btn--dark' onClick={() => setShowConfirmation(false)}>Close</button>
        </div>
      </div>
      </>
    )}

    {showRecommended && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowRecommended(false)}>
            <FaTimes />
          </div>

          <div>
            <div className='buyerdashboard__recommendation-logo'>
              <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + selectedRecommendation.logo} />
            </div>

            <div style={{fontSize: '16px', fontWeight: 'bold'}}>
              {selectedRecommendation.name}
            </div>

            {/* <div style={{marginTop: '10px', fontSize: '12px'}}>
              {selectedRecommendation.description}
            </div> */}

            {workEmailVerified === false && (
              <div style={{marginTop: '30px', fontWeight: 'bold'}}>
                <i>
                  Before you book a demo you must have a valid work email on file. Only takes 1-2 minutes.
                </i>

                <div className='mt-30'>
                  <Link to={'/account/work-email'} className='btn'>Verify your work email.</Link>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    )}

    {showMaxReached && (
      <>
        <div className='overlay'></div>
        <div className='modal'>
          <div className='modal__close' onClick={() => setShowMaxReached(false)}>
            <FaTimes />
          </div>

          <p>
            You have reached your maximum applications per day. Please come back tomorrow to apply for more.
          </p>
        </div>
      </>
    )}
    </>
  )
}

export default BuyerDemosAvailable;