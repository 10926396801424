import React from 'react';
import {
  Image,
  Center,
  VStack,
  Text,
  Heading,
  Button,
  Box,
} from '@chakra-ui/react';
import apply from './homeAssets/apply.png';
import discover from './homeAssets/discover.png';
import getPaid from './homeAssets/getPaid.png';
import cloud from './homeAssets/cloudIcon.png';
import signUp from './homeAssets/signUpIcon.png';

const HowItWorksMobile = () => {
  return (
    <>
      <Box py='30px' bg='#d4f4f9'>
        <Center px='30px' mt='50px' mb='100px'>
          <VStack>
            <Heading mb='80px'>How Does It All Work?</Heading>
            {/* Centering content vertically */}
            <VStack align='center' spacing='4'>
              <Image src={apply} maxW='100%' mb='20px' />
              {/* Setting the direction to 'column' and centering children */}
              <Heading
                fontFamily='poppins'
                fontSize={['28px', '28px', '42px']} // Responsive font size
                fontWeight='700'
                lineHeight={['36px', '36px', '50px']} // Responsive line height
                pb='30px'
                textAlign='center' // Center heading on mobile
              >
                Get Invited To Paid Demos
              </Heading>
              <Text
                textAlign='center'
                w={['90%', '90%', '520px']} // Responsive width
                fontFamily='poppins'
                fontSize='18px'
                fontWeight='400'
                lineHeight='32px'
                color='#1D191F'
                pb='30px'>
                Browse our community of Software Vendors and easily apply to
                paid demos. Applying is super easy and only takes about two
                minutes per application.
              </Text>
              <Button
                as='a'
                href='https://www.leadrpro.com/software'
                color='#FFF'
                w={['90%', '250px']} // Responsive width
                h='50px'
                p='10px 80px'
                fontFamily='poppins'
                borderRadius='68px'
                bg='#7107A8'
                _hover={{
                  bg: 'purple.700',
                  transform: 'scale(0.98)',
                }}>
                {/* <Image src={cloud} w='28px' mr='10px' /> */}
                Browse Software
              </Button>
              <Button
                as='a'
                href='https://www.leadrpro.com/signup'
                bg='#FFF'
                color='#7107A8'
                fontFamily='poppins'
                w={['90%', '250px']} // Responsive width
                h='50px'
                p='10px 80px'
                borderRadius='68px'
                border='1px solid #7107A8'
                _hover={{
                  bg: 'grey.300',
                  transform: 'scale(0.98)',
                  border: '2.5px solid #7107A8',
                }}>
                <Image src={signUp} w='25px' mr='10px' />
                Sign up free
              </Button>
            </VStack>
          </VStack>

          {/* Max width to make it responsive */}
        </Center>
        <Center px='30px' mb='100px'>
          {' '}
          {/* Centering content vertically */}
          <VStack align='center' spacing={4}>
            {' '}
            {/* Setting the direction to 'column' and centering children */}
            <Image src={discover} maxW='100%' mb='20px' />{' '}
            {/* Max width to make it responsive */}
            <Heading
              // w='90%'
              fontSize={['28px', '28px', '42px']} // Responsive font size
              fontFamily='poppins'
              fontWeight='700'
              lineHeight={['36px', '36px', '50px']} // Responsive line height
              pb='30px'
              textAlign='center' // Center heading on mobile
            >
              Discover New Software
            </Heading>
            <Text
              textAlign='center'
              w={['90%', '520px']} // Responsive width
              fontFamily='poppins'
              fontSize='18px'
              fontWeight='400'
              lineHeight='32px'
              color='#1D191F'
              pb='30px'>
              Discover new and innovative software solutions for your business.
              Our product demos are hosted using an internal video tool and
              usually take about 30 mins.
            </Text>
            <VStack>
              <Button
                as='a'
                href='https://www.leadrpro.com/software'
                color='#FFF'
                w={['90%', '250px']} // Responsive width
                h='50px'
                p='10px 80px'
                fontFamily='poppins'
                borderRadius='68px'
                bg='#7107A8'
                _hover={{
                  bg: 'purple.700',
                  transform: 'scale(0.98)',
                }}>
                {/* <Image src={cloud} w='28px' mr='10px' /> */}
                Browse Software
              </Button>
              <Button
                as='a'
                href='https://www.leadrpro.com/signup'
                bg='#FFF'
                color='#7107A8'
                fontFamily='poppins'
                w={['90%', '250px']} // Responsive width
                h='50px'
                p='10px 80px'
                borderRadius='68px'
                border='1px solid #7107A8'
                _hover={{
                  bg: 'grey.300',
                  transform: 'scale(0.98)',
                  border: '2.5px solid #7107A8',
                }}>
                <Image src={signUp} w='25px' mr='10px' />
                Sign up free
              </Button>
            </VStack>
          </VStack>
        </Center>
        <Center px='30px' mt='50px' mb='100px'>
          {' '}
          {/* Centering content vertically */}
          <VStack align='center' spacing='4'>
            <Image src={getPaid} maxW='100%' mb='20px' />
            {/* Setting the direction to 'column' and centering children */}
            <Heading
              fontFamily='poppins'
              fontSize={['28px', '28px', '42px']} // Responsive font size
              fontWeight='700'
              lineHeight={['36px', '36px', '50px']} // Responsive line height
              pb='30px'
              textAlign='center' // Center heading on mobile
            >
              Get Paid For Your Time
            </Heading>
            <Text
              textAlign='center'
              w={['90%', '90%', '520px']} // Responsive width
              fontFamily='poppins'
              fontSize='18px'
              fontWeight='400'
              lineHeight='32px'
              color='#1D191F'
              pb='30px'>
              We pay out $75 for every demo completed on LeadrPro! Once your
              demo is completed, you can cash out using PayPal, Venmo, CashApp,
              Zelle, Amazon Gift Card or Visa.
            </Text>
            <Button
              as='a'
              href='https://www.leadrpro.com/software'
              color='#FFF'
              w={['90%', '250px']} // Responsive width
              h='50px'
              p='10px 80px'
              fontFamily='poppins'
              borderRadius='68px'
              bg='#7107A8'
              _hover={{
                bg: 'purple.700',
                transform: 'scale(0.98)',
              }}>
              {/* <Image src={cloud} w='28px' mr='10px' /> */}
              Browse Software
            </Button>
            <Button
              as='a'
              href='https://www.leadrpro.com/signup'
              bg='#FFF'
              color='#7107A8'
              fontFamily='poppins'
              w={['90%', '250px']} // Responsive width
              h='50px'
              p='10px 80px'
              borderRadius='68px'
              border='1px solid #7107A8'
              _hover={{
                bg: 'grey.300',
                transform: 'scale(0.98)',
                border: '2.5px solid #7107A8',
              }}>
              <Image src={signUp} w='25px' mr='10px' />
              Sign up free
            </Button>
          </VStack>
          {/* Max width to make it responsive */}
        </Center>
      </Box>
    </>
  );
};

export default HowItWorksMobile;
