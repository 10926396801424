import React from 'react';
import { Box, Flex, Image, Center, Text, Spacer } from '@chakra-ui/react';

const BuyerLogoMap = () => {
  return (
    <>
      <Box w='100%'>
        <Flex
          direction={'column'}
          boxSize='full'
          bg='white'
          px={10}
          alignItems='center'
          justifyContent='center'>
          <Box>
            <Center>
              <Text
                mt='50px'
                fontFamily='poppins'
                fontWeight='semibold'
                mb={10}
                fontSize={['40px', , '40px']}
                textAlign='center'>
                Join Our Community
              </Text>
            </Center>
          </Box>
          {/* ROW 1 */}

          <div className='center'>
            <img src='https://ik.imagekit.io/ivgrhmd4k/logos_1920.648128da.jpg?updatedAt=1710624157032' alt='' style={{width: '80%'}} />
          </div>
        </Flex>
      </Box>
    </>
  );
};

export default BuyerLogoMap;
