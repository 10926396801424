import './Company.css';
import './CompanyReviews.css';
import star_selected from '../../assets/design/rating-star-selected.png';
import star_deselect from '../../assets/design/rating-star-unselected.png';
import { useEffect, useState } from 'react';
import close from '../../assets/design/dashboard/close.png';
import { searchCompanyCategory, getCompany, getCompanyCategories, getCompanyReviewAverages, getCompanyReviews, getBuyerDemoFromCompanyId, getPublicCompanyMedia, refreshToken, addToWaitlist, createSellerDemo, addCompanyView, getUserProfile, workEmailIsVerified, saveCompany, unsaveCompany, userIsOwner, addDemoMessages, addCompanyClick } from '../../utils/api';
import { Link, useParams } from 'react-router-dom';
import SellerConfirm from '../search/SellerConfirm';
import { Helmet } from "react-helmet";
import BuyerBookingSellerFromCompany from '../../components/demos/BuyerBookingSellerFromCompany';
import QualifierQuestions from '../../components/qualifier/QualifierQuestions';
import AvailableTimes from '../../components/qualifier/AvailableTimes';
import { isAuthed, refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import ScrollToTop from '../../components/layout/ScrollToTop';
import { FaCheck, FaClock, FaRegClock, FaUsers, FaStar, FaRegStar, FaHeart, FaRegHeart, FaShare, FaRegCommentDots, FaTimes, FaRegCheckCircle, FaCheckCircle, FaRegBell } from 'react-icons/fa';
import FadeIn from '../../utils/fadein';
import { getSeoName, checkSession } from '../../utils/utils';

function CompanyReviews() {
  const [company, setCompany] = useState({ companies: [] });
  const [categories, setCategories] = useState([]);
  const [reviewAverages, setReviewAverages] = useState([]);
  const [reviews, setReviews] = useState([]);
  const [showBooking, setShowBooking] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [hasBooked, setHasBooked] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showQualifier, setShowQualifier] = useState(false);
  const [showSelectedTime, setShowSelectedTime] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentDemo, setCurrentDemo] = useState({});
  const [hasDemo, setHasDemo] = useState(false);
  const [media, setMedia] = useState([]);
  const [showScreenshot, setShowScreenshot] = useState(false);
  const [selectedScreenshot, setSelectedScreenshot] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [showWaitlist, setShowWaitlist] = useState(false);
  const [showWaitlistConfirm, setShowWaitlistConfirm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [userIsOnWaitlist, setUserIsOnWaitlist] = useState(false);
  const [painPoint, setPainPoint] = useState('');
  const [icp, setIcp] = useState('');
  const [better, setBetter] = useState('');
  const [isVerified, setIsVerified] = useState(false);
  const [showUnverified, setShowUnverified] = useState(false);
  const [isEnabled, setIsEnabled] = useState(true);
  const [profileLoaded, setProfileLoaded] = useState(false);
  let { id } = useParams();
  const [applied, setApplied] = useState(false);
  const [showReviews, setShowReviews] = useState(true);
  const [isOwnerLoaded, setIsOwnerLoaded] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [submittingMessage, setSubmittingMessage] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  let imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(async function () {
    await checkIsOwner();
    await loadCompany();
    //await loadCategories();
    await loadReviewAverages();
    await loadReviews();
    //await loadMedia();
    await addCompanyView(id);
    await loadUserProfile();
    await checkSession('Company Reviews');
  }, []);

  async function checkIsOwner() {
    let res = await userIsOwner(id);

    if (res.success) {
      setIsOwner(res.data);
      setIsOwnerLoaded(true);
    }

    else if (res.status === 403) {
      await refresh();

      let res = await userIsOwner(id);

      if (res.success) {
        setIsOwner(res.data);
        setIsOwnerLoaded(true);
      }
    }
  }

  async function loadUserProfile() {
    let res = await getUserProfile();

    if (res.success) {
      let _user = res.data;
      if (_user.work_email_verified) {
        setIsVerified(true);
      }

      else {
        setIsVerified(false);
      }

      if (_user.is_disabled) {
        setIsEnabled(false);
      }

      else {
        setIsEnabled(true);
      }

      setProfileLoaded(true);
    }

    else if (res.status === 403) {
      let _refresh = await refreshToken();

      if (_refresh.success) {
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let res = await getUserProfile();

        if (res.success) {
          let _user = res.data;
          if (_user.work_email_verified) {
            setIsVerified(true);
          }

          else {
            setIsVerified(false);
          }

          if (_user.is_disabled) {
            setIsEnabled(false);
          }

          else {
            setIsEnabled(true);
          }

          setProfileLoaded(true);
        }
      }
    }
  }

  function getStars(number) {
    if (!Number.isInteger(Math.ceil(number)))
      number = 0;

    let el = [];
    for (let i = 0; i < number; i++) {
      el.push(<span className="review__star"><FaStar /></span>);
    }

    for (let i = 0; i < 5 - number; i++) {
      el.push(<span className="review__star--unselected"><FaRegStar /></span>);
    }

    return (
      <div>
        {el}
      </div>
    )
  }

  async function loadReviewAverages() {
    let response = await getCompanyReviewAverages(id);

    if (response.success) {
      setReviewAverages(response.data);
    }
  }

  async function loadCompany() {
    let response = await getCompany(id);

    if (response.success) {
      setCompany(response.data[0]);
      setIsSaved(response.data[0].saved);
      setUserIsOnWaitlist(response.data[0].user_on_waitlist !== '0');

      if (response.data[0].pain_point)
        setPainPoint(response.data[0].pain_point.replace(/\n/g, '<br>'));

      if (response.data[0].ideal_customer_profile)
        setIcp(response.data[0].ideal_customer_profile.replace(/\n/g, '<br>'));

      if (response.data[0].unique_value_proposition)
        setBetter(response.data[0].unique_value_proposition.replace(/\n/g, '<br>'));
    }

    else if (response.status === 403) {
      let _refresh = await refreshToken();

      if (_refresh.success) {
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let response = await getCompany(id);

        if (response.success) {
          setCompany(response.data[0]);
          setIsSaved(response.data[0].saved);
        }
      }
    }

    setLoaded(true);
  }

  async function loadReviews() {
    let response = await getCompanyReviews(id);

    if (response.success) {
      setReviews(response.data);
    }
  }

  async function demoAccepted() {
    setApplied(true);
  }

  async function handleWaitlistClick(demo_id, item) {
    setShowWaitlist(true);
  }

  async function handleWaitlistConfirmClick() {
    setSubmitting(true);
    let res = await addToWaitlist(id);

    if (res.success) {
      setShowWaitlistConfirm(true);
      setShowWaitlist(false);
      setSubmitting(false);
      setUserIsOnWaitlist(true);
      loadCompany();
    }

    else if (res.status === 403) {
      await refresh();
      let res = await addToWaitlist(id);

      if (res.success) {
        setShowWaitlistConfirm(true);
        setShowWaitlist(false);
        setSubmitting(false);
        setUserIsOnWaitlist(true);
        loadCompany();
      }
    }
  }

  async function loadCurrentDemo() {
    let _res = await getBuyerDemoFromCompanyId(id);

    if (_res.success) {
      if (_res.data.length > 0) {
        setCurrentDemo(_res.data[0]);
        setHasDemo(true);
      }

      else {
        setHasDemo(false);
        alert('no demo');
      }
    }
  }

  //loadCurrentDemo();

  async function handleApplyClick() {
    let _verified = await workEmailIsVerified();

    if (_verified.success) {
      if (_verified.data === false) {
        setShowUnverified(true);
        return;
      }
    }

    else if (_verified.status === 403) {
      await refresh();

      let _verified = await workEmailIsVerified();

      if (_verified.success) {
        if (_verified.data === false) {
          setShowUnverified(true);
          return;
        }
      }
    }

    setSubmitting(true);
    let _res = await getBuyerDemoFromCompanyId(id);

    if (_res.success) {
      if (_res.data.length > 0) {
        setCurrentDemo(_res.data[0]);
        setHasDemo(true);
        setShowQualifier(true);
      }

      else {
        let data = { seller_id: company.seller_id };
        let _res = await createSellerDemo(data);

        if (_res.success) {
          let _res = await getBuyerDemoFromCompanyId(id);

          if (_res.success) {
            if (_res.data.length > 0) {
              setCurrentDemo(_res.data[0]);
              setHasDemo(true);
              setShowQualifier(true);
            }
          }
        }
      }
    }

    else if (_res.status === 403) {
      let _refresh = await refreshToken();

      if (_refresh.success) {
        localStorage.setItem('access_token', _refresh.data.accessToken);
        localStorage.setItem('refresh_token', _refresh.data.refreshToken);

        let _res = await getBuyerDemoFromCompanyId(id);

        if (_res.success) {
          if (_res.data.length > 0) {
            setCurrentDemo(_res.data[0]);
            setHasDemo(true);
            setShowQualifier(true);
          }

          else {
            let data = { seller_id: company.seller_id };
            let _res = await createSellerDemo(data);

            if (_res.success) {
              let _res = await getBuyerDemoFromCompanyId(id);

              if (_res.success) {
                if (_res.data.length > 0) {
                  setCurrentDemo(_res.data[0]);
                  setHasDemo(true);
                  setShowQualifier(true);
                }
              }
            }
          }
        }
      }
    }

    setSubmitting(false);
    await addCompanyClick(id, 'Company reviews');
  }

  async function handleSaveClick() {
    let res = await saveCompany(id);

    if (res.success) {
      setIsSaved(true);
    }

    else if (res.status === 403) {
      await refresh();

      let res = await saveCompany(id);

      if (res.success) {
        setIsSaved(true);
      }
    }
  }

  async function handleUnsaveClick() {
    let res = await unsaveCompany(id);

    if (res.success) {
      setIsSaved(false);
    }

    else if (res.status === 403) {
      await refresh();

      let res = await unsaveCompany(id);

      if (res.success) {
        setIsSaved(false);
      }
    }
  }

  async function handleSendMessage() {
    if (messageText.length == 0) {
      return;
    }

    let tmpid = null;

    setSubmittingMessage(true);

    let _res = await getBuyerDemoFromCompanyId(id);

    if (_res.success) {
      if (_res.data.length > 0) {
        setCurrentDemo(_res.data[0]);
        setHasDemo(true);
        tmpid = _res.data[0].reference_id;

        //console.log('tmpid 2', tmpid);
      }

      else {
        let data = { seller_id: company.seller_id };
        let _res = await createSellerDemo(data);

        if (_res.success) {
          let _res = await getBuyerDemoFromCompanyId(id);

          if (_res.success) {
            if (_res.data.length > 0) {
              setCurrentDemo(_res.data[0]);
              setHasDemo(true);
              tmpid = _res.data[0].reference_id;

              //     console.log('tmpid 2', tmpid);

            }
          }
        }
      }
    }

    else if (_res.status === 403) {
      await refresh();

      let _res = await getBuyerDemoFromCompanyId(id);

      if (_res.success) {
        if (_res.data.length > 0) {
          setCurrentDemo(_res.data[0]);
          setHasDemo(true);
          tmpid = _res.data[0].reference_id;


        }

        else {
          let data = { seller_id: company.seller_id };
          let _res = await createSellerDemo(data);

          if (_res.success) {
            let _res = await getBuyerDemoFromCompanyId(id);

            if (_res.success) {
              if (_res.data.length > 0) {
                setCurrentDemo(_res.data[0]);
                setHasDemo(true);
                tmpid = _res.data[0].reference_id;

                console.log('tmpid 4', tmpid);

              }
            }
          }
        }
      }
    }

    let res = await addDemoMessages(tmpid, messageText);

    if (res.success) {
      setShowChat(false);
      setSubmittingMessage(false);
      setMessageSent(true);
    }

    else if (res.status === 403) {
      await refresh();

      let res = await addDemoMessages(currentDemo.reference_id, messageText);

      if (res.success) {
        setShowChat(false);
        setSubmittingMessage(false);
        setMessageSent(true);
      }
    }
  }

  function generateMetaDescription(inputString) {
    const maxLength = 160;
    if (inputString.length <= maxLength) {
      return inputString;
    } else {
      // Truncate the string to the maximum length minus 1 (for the ellipsis)
      // and ensure we don't cut off in the middle of a word.
      let trimmedString = inputString.substr(0, maxLength - 1);
      trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
      return `${trimmedString}…`;
    }
  }

  return (
    <div className='company__wrapper'>
      <ScrollToTop />
      <div className='company container' style={{ background: 'white' }}>
        {loaded === true && (
          <Helmet>
            <title>{`${company.name} Reviews 2024`}</title>

            {(company.description == null || company.description.length == 0) && (
              <meta name='description' content={`Book a 30-minute discovery demo with ${company.name} and earn $75. Discover the latest in innovative software solutions while getting paid.`} />
            )}

            {company.description != null && company.description.length > 0 && (
              <meta name='description' content={generateMetaDescription(company.description)} />
            )}

            <link rel="canonical" href={`https://www.leadrpro.com/company/${company.company_id}/${company.url_slug}`} />

            <meta property="og:title" content={`${company.name} - Company info, pricing and reviews`} />
            <meta property="og:type" content="product" />
            <meta property="og:url" content={`https://www.leadrpro.com/reviews/${company.company_id}/${company.url_slug}`} />

            {company.thumbnail_image && company.thumbnail_image.length > 0 && (
              <meta property="og:image" content={baseImageUrl + 'companythumbnails/tr:di-@@companythumbnails@@default-buyer.png/' + company.thumbnail_image} />
            )}

            {company.description && company.description.length > 0 && (
              <meta property="og:description" content={generateMetaDescription(company.description)} />
            )}
          </Helmet>
        )}

        {loaded === false && (
          <>
            <div style={{ backgroundColor: "#ddd", opacity: '.4', borderRadius: '10px', height: '400px', boxShadow: '2px 2px 9px #ccc', marginBottom: '10px' }}>
            </div>

            <div style={{ backgroundColor: "#ddd", opacity: '.4', borderRadius: '10px', height: '400px', boxShadow: '2px 2px 9px #ccc', marginBottom: '10px' }}>
            </div>
          </>
        )}

        {loaded === true && (
          <>
            <div style={{ marginBottom: '15px' }}>
              <Link className='link' to={`/company/${company.company_id}/${company.url_slug}`}>&lt; Back to company profile</Link>
            </div>

            <div className="company__header">
              <div className="company__stats">
                <div className="company__logo">
                  {company.logo && (
                    <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + company.logo} alt="" />
                  )}

                  {!company.logo && (
                    <span className="searchresults__company-initial company__initial">
                      {company.name && company.name[0]}
                    </span>
                  )}
                </div>

                <div className="company__details">
                  {/* {company.website && company.website.length > 0 && (
            <div className='company__link'>
              <a href={company.website.startsWith('http') ? company.website : 'https://' + company.website} rel='nofollow'>Company Website</a>
            </div>
          )} */}

                  <h1 className="company__name">{company.name}</h1>

                  {company.industry_name && (
                    <div className="company__industry">{company.industry_name}</div>
                  )}

                  <div className="company__review">
                    {company.average_rating && (
                      <div className="company__stars">
                        {getStars(Math.ceil(company.average_rating))}

                        <span className="company__star-count">{Math.round(company.average_rating * 10) / 10} out of 5</span>
                      </div>
                    )}

                    {company.average_rating == null && (
                      <div className="company__stars">
                        {getStars(Math.ceil(0))}

                        <span className="company__star-count">No reviews</span>
                      </div>
                    )}
                  </div>

                  {loaded && company.buyer_feedback.length > 0 && (
                    <div>
                      <a href='#reviews' className='company__link'>{company.buyer_feedback.length} reviews</a>
                    </div>
                  )}
                </div>
              </div>

              <div className="company__info-btns">
                {isAuthed() && !showConfirmation && (
                  <>
                    {profileLoaded == false && (
                      <Spinner />
                    )}

                    {loaded && profileLoaded && (company.is_seller === false || company.is_paused == true || company.is_inactive == true) && (
                      <div style={{ margin: '20px 0px' }}>
                        {company.user_on_waitlist === false && (
                          <button onClick={() => handleWaitlistClick()} className='btn company__btn-notify'><FaRegBell /> Notify me when available</button>
                        )}

                        {company.user_on_waitlist && (
                          <span className='browsesellers__on-waitlist'><FaCheckCircle style={{ color: 'mediumseagreen' }} /> You're on the list</span>
                        )}
                      </div>
                    )}

                    {loaded == true && profileLoaded === true && company.on_waitlist === false && company.demo_pending === false && company.is_seller && company.is_paused === false && company.is_inactive === false && (
                      <div className='company__btns'>
                        {submitting === false && applied === false && isEnabled === true && isOwner == false && isOwnerLoaded && (
                          <button className='btn company__btn-book' onClick={() => handleApplyClick()}>Book a Demo {'(Earn $75)'}</button>
                        )}

                        {isOwnerLoaded == false && (
                          <Spinner />
                        )}

                        {submitting && (
                          <Spinner />
                        )}
                      </div>
                    )}

                    {loaded == false && (
                      <Spinner />
                    )}

                    {company.on_waitlist === false && (company.demo_pending || applied === true) && (
                      <div className='company__application-pending' style={{ fontSize: 'small' }}>
                        <FaRegClock /> <strong>You've applied to demo. Hang tight!</strong>
                      </div>
                    )}

                    <div className='company__options'>
                      {isAuthed() == true && company.is_seller === true && company.is_paused === false && company.is_inactive === false && (
                        <div className='company__option' onClick={() => setShowChat(true)}><FaRegCommentDots /> Chat</div>
                      )}

                      {isSaved == false && (
                        <div className='company__option' onClick={() => handleSaveClick()}>
                          <FaRegHeart /> <span>Save</span>
                        </div>
                      )}

                      {isSaved == true && (
                        <div className='company__option' onClick={() => handleUnsaveClick()}>
                          <FaHeart style={{ color: 'salmon' }} /> <span>Saved</span>
                        </div>
                      )}

                      <div className='company__option' onClick={() => setShowShare(true)}><FaShare /> Share</div>
                    </div>

                    {/* {company.on_waitlist === true && (
                <>
                  {!userIsOnWaitlist && (
                    <span>
                      <button className='btn btn--primary' onClick={() => handleWaitlistClick()}>Join the Waitlist</button>
                    </span>
                  )}

                  {userIsOnWaitlist && (
                    <span>
                      You're on the waitlist
                    </span>
                  )}
                </>
              )} */}
                  </>
                )}

                {!isAuthed() && (
                  <div style={{ marginTop: '40px' }}>
                    <a href='/signup' className='btn btn--primary company__btn-signup '>Create free account to book a demo</a>
                  </div>
                )}
              </div>
            </div>
          </>
        )}

        {loaded && (
          <section className="company__info">
            {company.description && (
              <div>
                {company.description}
              </div>
            )}

            {!company.description && loaded === true && (
              <p>
                No description found
              </p>
            )}
          </section>
        )}

        {company && company.features && company.features.length > 0 && (
          <div className='company__features'>
            <h2>Features</h2>
            {company.features.map(x => (
              <div className='company__feature'>
                <FaCheck /> <span style={{ marginLeft: '20px' }}>{x.feature_text}</span>
              </div>
            ))}
          </div>
        )}

        {company && company.pricing && company.pricing.length > 0 && (
          <>
            <div className='company__pricing'>
              <h2>Pricing</h2>

              <div className='company__pricing-list'>
                {company.pricing.map(price => (
                  <div className='company__pricing-item'>
                    <div className='company__pricing-item-name'>{price.name}</div>
                    <div className='company__pricing-item-price'>${price.price}</div>
                    <div className='company__pricing-item-unit'>{price.unit}</div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}

        {loaded && company.buyer_feedback.length === 0 && showReviews && (
          <div className="company__reviews">
            <strong>No reviews posted yet...</strong>
          </div>
        )}

        {loaded && company.buyer_feedback.length > 0 && showReviews && (
          <section className="company__reviews" id='reviews'>
            <h2>Reviews {`(${company.buyer_feedback.length})`}</h2>

            <div className='company__ratings'>
              {loaded && company.buyer_feedback.map((item, index) => (
                <div className='company__rating' key={index}>
                  <div className='company__rating-date'>{new Date(item.date_created).toLocaleDateString()}</div>
                  <div className='company__rating-header'>{item.job_title} working in {item.industry ?? 'Software'}</div>

                  <div className='company__rating-stats'>
                    <span className='company__rating-stats-label'><FaUsers /> Company size: </span>
                    <span>{item.buyer_company_size}</span>
                  </div>

                  <div className='company__rating-stars'>
                    {item.pain_point && (
                      <div><span>Does this solution solve a pain point?</span> {getStars(parseInt(item.pain_point))}</div>
                    )}

                    {item.ease_of_integration && (
                      <div><span>How you rate the ease of integration?</span> {getStars(parseInt(item.ease_of_integration))}</div>
                    )}

                    {item.likely_to_recommend && (
                      <div><span>Would you recommend this solution?</span> {getStars(parseInt(item.likely_to_recommend))}</div>
                    )}

                    {item.overall_demo && (
                      <div><span>How would you rate the overall demo experience?</span> {getStars(parseInt(item.overall_demo))}</div>
                    )}
                  </div>

                  <div className='company__rating-feedback1'>
                    "{item.feedback1}"
                  </div>
                </div>
              ))}
            </div>
          </section>
        )}

        {showBooking && (
          <>
            <div className="overlay"></div>
            <BuyerBookingSellerFromCompany selectedBuyer={company} setShowBooking={setShowBooking} setShowConfirm={setShowConfirm} setHasBooked={setHasBooked} />
          </>
        )}

        {showConfirm && (
          <div className="overlay">
            <SellerConfirm company={company} setShow={setShowConfirm} />
          </div>
        )}

        {showQualifier && (
          <>
            <div className='overlay'></div>
            <div className='modal modal--pad0'>
              <QualifierQuestions currentDemo={currentDemo} demo_id={currentDemo.demo_id} setShowQualifier={setShowQualifier} setShowSelectedTime={setShowSelectedTime}></QualifierQuestions>
            </div>
          </>
        )}

        {showSelectedTime && (
          <div className='overlay'>
            <div className='modal modal--pad0 modal--wide'>
              <AvailableTimes demo={currentDemo} demo_id={currentDemo.demo_id} company_name={currentDemo.company_name} seller_id={currentDemo.seller_id} setShowSelectedTime={setShowSelectedTime} showSteps={false} setShowConfirmation={setShowConfirmation} demoAccepted={demoAccepted} />
            </div>
          </div>
        )}

        {showConfirmation && (
          <>
            <div className='overlay'></div>
            <div className='modal'>
              <img src={close} className="modal__close" onClick={() => setShowConfirmation(false)} />

              <div className='center' style={{ fontSize: "50px", marginBottom: "20px" }}>
                🎉
              </div>

              <p className=''>Your request is now being reviewed. You should hear back within 24 hours.</p>

              <p className=''>In the meantime, keep your profile up to date and check back daily for more demo opportunities!</p>

              <div className='center' style={{ marginTop: "20px" }}>
                <button className='btn btn--primary btn--dark' onClick={() => setShowConfirmation(false)}>Close</button>
              </div>
            </div>
          </>
        )}
      </div>

      {showWaitlist && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowWaitlist(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2' style={{ lineHeight: '1.1' }}>Get notified when <span style={{ color: 'mediumpurple', textDecoration: 'underline' }}>{company.name}</span> becomes available!</h2>

            <div className='mt-20'>You will be added to the waitlist and will be notified as soon as this company is ready to host demos.</div>

            <div className='center mt-20'>
              {submitting === false && (
                <button className='btn btn--primary btn--full' style={{ padding: '10px' }} onClick={() => handleWaitlistConfirmClick()}><FaRegBell /> Sign me up</button>
              )}

              {submitting && (
                <Spinner />
              )}
            </div>
          </div>
        </>
      )}

      {showWaitlistConfirm && (
        <>
          <div className='overlay'></div>

          <div className='modal'>
            <div className='modal__close' onClick={() => setShowWaitlistConfirm(false)}>
              <FaTimes />
            </div>

            <div className='center' style={{ fontSize: '30px', color: 'mediumseagreen' }}><FaCheckCircle /></div>

            <h3 className='modal__h2'>
              You have been added to the waitlist.
            </h3>

            <p className='mt-20'>
              You will be notified once this company is ready to host demos.
            </p>
          </div>
        </>
      )}

      {showUnverified === true && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <h2 className='modal__h2'>
              ⚠️ Complete setting up your account!
            </h2>
            <p style={{ marginTop: "20px" }}>
              You must verify your work email before you can apply for a demo.
            </p>

            <p>
              <a href='/account/work-email' className='link'>Verify work email</a>
            </p>
            <div className='center' style={{ marginTop: "30px" }}>
              <button className='btn btn--secondary' onClick={() => setShowUnverified(false)}>Close</button>
            </div>
          </div>
        </>
      )}

      {showChat && (
        <>
          <div className='overlay'></div>
          <div className='modal company__modal-chat'>
            <div className='modal__close' onClick={() => setShowChat(false)}><FaTimes /></div>

            <div style={{ fontSize: '14px', fontWeight: 'bold', color: '#999', marginBottom: '10px' }}>Start a chat</div>

            <div>
              {/* <div className="company__logo">
            {company.logo && (
              <img src={imageUrl + 'tr:di-@@companylogos@@default-buyer.png/' + company.logo} alt="" />
              )}
                  
              {!company.logo && (
                <span className="searchresults__company-initial company__initial">
                  {company.name && company.name[0]}
                </span>
              )}
            </div> */}

              <div className="company__details" style={{ padding: '0px' }}>
                <div className="company__name">{company.name}</div>

                {company.industry_name && (
                  <div className="company__industry">{company.industry_name}</div>
                )}

                <div className="company__review">
                  {company.average_rating && (
                    <div className="company__stars">
                      {getStars(Math.ceil(company.average_rating))}

                      <span className="company__star-count">{Math.round(company.average_rating * 10) / 10} out of 5</span>
                    </div>
                  )}

                  {company.average_rating == null && (
                    <div className="company__stars">
                      {getStars(Math.ceil(0))}

                      <span className="company__star-count">No reviews posted  yet</span>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <div style={{ marginBottom: '10px' }}><strong>Send a message</strong></div>
              <textarea className='textarea input' value={messageText} onChange={(e) => setMessageText(e.target.value)} placeholder='Leave a message'></textarea>
            </div>

            <div style={{ marginTop: '20px' }}>
              {submittingMessage == false && (
                <button className='btn btn--primary btn--send' onClick={() => handleSendMessage()}>Send Message</button>
              )}

              {submittingMessage && (
                <Spinner />
              )}
            </div>
          </div>
        </>
      )}

      {showShare && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowShare(false)}><FaTimes /></div>

            <div className='modal__h2'>
              Share this company with a friend
            </div>

            <div style={{ marginTop: '20px' }}>
              Earn $250 for every new seller and $50 for each new buyer that you refer to our platform.
            </div>

            <div style={{ marginTop: '20px' }}>
              <a className='link' href='https://leadrpro.partnerstack.com/?group=leadrproaffiliatepartners' target='_blank'>Join our referral program</a>
            </div>
          </div>
        </>
      )}

      {messageSent && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setMessageSent(false)}><FaTimes /></div>

            <div>
              <div className='center' style={{ fontSize: '40px' }}>
                <FaRegCheckCircle />
              </div>

              <div style={{ fontWeight: 'bold' }} className="center">
                Message successfully sent. Please wait 24-48hrs for a response.
              </div>

              <div className='center' style={{ marginTop: '20px' }}>
                <button className='btn btn--small' onClick={() => setMessageSent(false)}>Close</button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CompanyReviews;
