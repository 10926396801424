import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getPointActivity, createPointActivity, getPointUserActivity } from '../../utils/api/admin';
import './PointActivity.css';

function UserPointActivity() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pointsEarned, setPointsEarned] = useState('');
  const [totalPoints, setTotalPoints]  =useState(0);
  const [pointsForRegistration, setPointsForRegistration] = useState(0);
  const [pointsForEvent, setPointsForEvent] = useState(0);
  const [pointsForApplications, setPointsForApplications] = useState(0);
  const [costPerCredit, setCostPerCredit] = useState(.25);
  const [query, setQuery] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getPointUserActivity();

    if (res.success){
      setData(res.data);
      let total = 0;
      let total_registration = 0;
      let total_events = 0;
      let total_applications = 0;

      for (let i = 0; i < res.data.length; i++){
        total += parseInt(res.data[i].points_earned);

        if (res.data[i].activity_name == 'User registration'){
          total_registration += parseInt(res.data[i].points_earned);
        }

        else if (res.data[i].activity_name == 'Event registration'){
          total_events += parseInt(res.data[i].points_earned);
        }

        else if (res.data[i].activity_name == 'Demo application'){
          total_applications += parseInt(res.data[i].points_earned);
        }
      }

      setTotalPoints(total);
      setPointsForRegistration(total_registration);
      setPointsForEvent(total_events);
      setPointsForApplications(total_applications);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getPointUserActivity();

      if (res.success){
        setData(res.data);
        let total = 0;
        let total_registration = 0;
        let total_events = 0;
        let total_applications = 0;
  
        for (let i = 0; i < res.data.length; i++){
          total += parseInt(res.data[i].points_earned);
  
          if (res.data[i].activity_name == 'User registration'){
            total_registration += parseInt(res.data[i].points_earned);
          }
  
          else if (res.data[i].activity_name == 'Event registration'){
            total_events += parseInt(res.data[i].points_earned);
          }

          else if (res.data[i].activity_name == 'Demo application'){
            total_applications += parseInt(res.data[i].points_earned);
          }
        }
  
        setTotalPoints(total);
        setPointsForRegistration(total_registration);
        setPointsForEvent(total_events);
        setPointsForApplications(total_applications);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function handleCreate(){
    let res = await createPointActivity(name, description, pointsEarned);

    if (res.success){
      loadData();

      setName('');
      setDescription('');
      setPointsEarned('');
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  function handleQueryChange(val){
    setQuery(val);
  }

  function getActivityBadge(activityName) {
    switch (activityName) {
      case 'Demo approved':
        return <span className="badge bg-green-500 text-white text-sm" style={{padding: '2px 5px', borderRadius: '5px'}}>Demo approved</span>;
      case 'Demo application':
        return <span className="badge bg-blue-500 text-white text-sm" style={{padding: '2px 5px', borderRadius: '5px'}}>Demo application</span>;
      case 'User registration':
        return <span className="badge bg-yellow-500 text-white text-sm" style={{padding: '2px 5px', borderRadius: '5px'}}>User registration</span>;
      default:
        return activityName;
    }
  }

  useEffect(() => {

  }, [query])

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Point User Activity</h1>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <div className='admindashboard__cards'>
            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Total Points</strong></div>
              <div className='admindashboard__card-value'>
                {totalPoints} / <span style={{fontSize: '16px', color: '#444'}}>
                  ${(totalPoints * costPerCredit).toFixed(2)}
                </span>
              </div>
            </div>

            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>User Registration</strong></div>
              <div className='admindashboard__card-value'>
                {pointsForRegistration} / <span style={{fontSize: '16px', color: '#444'}}>
                  ${(pointsForRegistration * costPerCredit).toFixed(2)}
                </span>
              </div>
            </div>

            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Event Registration</strong></div>
              <div className='admindashboard__card-value'>
                {pointsForEvent} / <span style={{fontSize: '16px', color: '#444'}}>
                  ${(pointsForEvent * costPerCredit).toFixed(2)}
                </span>
              </div>
            </div>

            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Demo Applications</strong></div>
              <div className='admindashboard__card-value'>
                {pointsForApplications} / <span style={{fontSize: '16px', color: '#444'}}>
                  ${(pointsForApplications * costPerCredit).toFixed(2)}
                </span>
              </div>
            </div>

            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Cost Per Credit</strong></div>

              <div className='mt-10'>
                <input type='text' className='input' placeholder='Cost per credit...' value={costPerCredit} onChange={(e) => setCostPerCredit(e.target.value)} />
              </div>
            </div>
          </div>

          <div>
            <strong>Records found: {data.length}</strong>
          </div>

          <div>
            <input type='text' className='input' placeholder='Search query...' value={query} onChange={(e) => handleQueryChange(e.target.value)} />
          </div>

          <table className='admindashboard__table table'>
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>User</th>
              <th>Source</th>
              <th>Points Earned</th>
              <th>Cash Value</th>
              <th>Status</th>
            </tr>

            {data.filter(x => 
              (query.length > 0 && 
                (x.registered_user_id == query || 
                 (x.first_name && x.first_name.toLowerCase().includes(query.toLowerCase())) || 
                 (x.last_name && x.last_name.toLowerCase().includes(query.toLowerCase()))
                )
              ) || query.length == 0
            ).map((item, index) => (
              <tr key={index}>
                <td>
                  {new Date(item.date_created).toLocaleString()}
                </td>

                <td>
                  {getActivityBadge(item.activity_name)}
                </td>

                <td>
                 <div>
                 #{item.registered_user_id} - {item.first_name} {item.last_name}
                 </div>

                 <div>
                  Company: {item.company_name}
                 </div>

                 <div>
                  Email: {item.email}
                 </div>

                 {item.is_suspended == true && (
                  <div>
                    <span style={{color: 'red'}}>Suspended</span>
                  </div>
                 )}
                </td>

                <td>
                  {item.source_type} - #{item.source_id}

                  {item.demo_status != null && (
                    <div>
                      <span style={{fontWeight: 'bold'}}>Demo Status:</span> {item.demo_status}
                    </div>
                  )}

                  {item.seller_company_name != null && (
                    <div>
                      {item.seller_company_name}
                    </div>
                  )}
                </td>

                <td>
                  +{item.points_earned}
                </td>

                <td>
                  <span style={{fontWeight: 'bold', fontSize: '22px'}}>
                    {(costPerCredit * item.points_earned).toFixed(2)}
                  </span>
                </td>

                <td>
                  {item.status == 'Pending' && (
                    <span className='admindashboard__yellow-flag'>Pending</span>
                  )}

                  {item.status == 'Approved' && (
                    <span className='admindashboard__green-flag'>Approved</span>
                  )}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default UserPointActivity;