import {
  Box,
  Image,
  Center,
  HStack,
  VStack,
  Text,
  Heading,
  Button,
  Spacer,
} from '@chakra-ui/react';
import sms from './homeAssets/smsSupport.png';
import cloud from './homeAssets/cloudIcon.png';

const HeroRight = () => {
  return (
    <>
      <Box mx='200px' mt='100px'>
        <Center>
          <HStack>
            <Image src={sms} w='300px' />
            <Spacer />

            <VStack align='start' pl='120px'>
              <Heading
                w='467px'
                fontSize='42px'
                fontFamily='poppins'
                fontWeight='700'
                lineHeight='50px'
                pb='30px'>
                LeadrPro SMS customer support
              </Heading>
              <Text
                textAlign='left'
                w='520px'
                fontFamily='poppins'
                fontSize='18px'
                fontWeight='400'
                lineHeight='32px'
                color='#1D191F'
                pb='30px'>
                We offer real-time SMS customer support during our business
                hours (8-6 PST). Have a question? Reach out anytime!
                <Text fontWeight='900'>1-844-415-5247</Text>
              </Text>
              <HStack>
                <Button
                  as='a'
                  href='https://www.leadrpro.com/app'
                  color='#FFF'
                  w='250px'
                  h='50px'
                  p='10px 80px'
                  fontFamily='poppins'
                  borderRadius='68px'
                  bg='#7107A8'
                  _hover={{
                    bg: 'purple.700',
                    transform: 'scale(0.98)',
                  }}>
                  <Image src={cloud} w='28px' mr='10px' />
                  Download app
                </Button>
                <Button
                  as='a'
                  href='https://www.leadrpro.com/signup'
                  bg='#FFF'
                  color='#7107A8'
                  fontFamily='poppins'
                  w='250px'
                  h='50px'
                  p='10px 80px'
                  borderRadius='68px'
                  border='1px solid #7107A8'
                  _hover={{
                    bg: 'grey.300',
                    transform: 'scale(0.98)',
                    border: '2.5px solid #7107A8',
                  }}>
                  Sign up free
                </Button>
              </HStack>
            </VStack>
          </HStack>
        </Center>
      </Box>
    </>
  );
};

export default HeroRight;
