import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getPointActivity, createPointActivity, getPointRewards, createPointReward, deletePointReward } from '../../utils/api/admin';
import { IKImage, IKContext, IKUpload } from 'imagekitio-react';
import './PointActivity.css';

function PointRewards() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pointsEarned, setPointsEarned] = useState('');
  const [pointCost, setPointCost] = useState('');
  const [quantity, setQuantity] = useState('');
  const [type, setType] = useState('');
  const [orderId, setOrderId] = useState('');
  const [dollarValue, setDollarValue] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [image, setImage] = useState('');
  const [costPerCredit, setCostPerCredit] = useState(.25);
  const [totalPoints, setTotalPoints] = useState(0);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'Rewards/';

  async function loadData(){
    let res = await getPointRewards();

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getPointRewards();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    setPointCost(parseInt(dollarValue * (4)));
  }, [dollarValue])

  useEffect(() => {

  }, [pointCost])

  async function handleCreate(){
    let res = await createPointReward(name, description, pointCost, quantity, type, orderId, dollarValue, image);

    if (res.success){
      loadData();

      setName('');
      setDescription('');
      setPointCost('');
      setQuantity('');
      setType('');
      setOrderId('');
      setDollarValue('');
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  function onImageError(){
    console.log('error');
  }

  async function onImageSuccess(e){
    setImage(e.name);
  }

  async function handleDelete(id){
    let res = await deletePointReward(id);

    if (res.success){
      loadData();
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Point Rewards</h1>

      {/* <div className='admindashboard__cards'>
            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Cost Per Credit</strong></div>

              <div className='mt-10'>
                <input type='text' className='input' placeholder='Cost per credit...' value={costPerCredit} onChange={(e) => setCostPerCredit(e.target.value)} />
              </div>
            </div>

            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Total Dollar Value</strong></div>

              <div className='mt-10' style={{fontSize: '25px', fontWeight: 'bold'}}>
                {(totalPoints * costPerCredit).toFixed(2)}
              </div>
            </div>
          </div> */}

      <div className='mt-10 mb-10'>
        <span className='btn' onClick={() => setShowAdd(!showAdd)}>Create Reward</span>
      </div>

    {showAdd && (
      <>
      <div className='overlay'></div>
      <div className='modal'>
        <div className='modal__close' onClick={() => setShowAdd(false)}>
          <FaTimes />
        </div>
        <h2 className='modal__h2'>Add Reward</h2>
        <div>
          <label>Name</label>
          <div>
            <input type='text' placeholder='' className='input' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>

        <div className='mt-10'>
          <label>Description</label>
          <div>
            <textarea className='input textarea' placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>
        </div>

        {/* <div>
          <label>Dollar Value</label>
          <div>
            <input type='text' placeholder='' className='input' value={dollarValue} onChange={(e) => setDollarValue(e.target.value)} />
          </div>
        </div>

        <div className='mt-10'>
          <label>Point Cost</label>
          <div>
            <input type='text' className='input' placeholder='Point cost' value={pointCost} onChange={(e) => setPointCost(e.target.value)} />
          </div>
        </div>

        <div className='mt-10'>
          <label>Quantity</label>
          <div>
            <input type='text' className='input' placeholder='Points earned' value={quantity} onChange={(e) => setQuantity(e.target.value)} />
          </div>
        </div> */}

        <div className='mt-10'>
          <label>Type</label>
          <div>
            <select className='input' value={type} onChange={(e) => setType(e.target.value)}>
              <option>Select type...</option>
              <option value='gift_card'>Gift card</option>
              <option value='cash'>Cash</option>
            </select>
          </div>
        </div>

        <div className='mt-10'>
          <label>Order ID</label>
          <div>
            <input type='text' className='input' placeholder='Points earned' value={orderId} onChange={(e) => setOrderId(e.target.value)} />
          </div>
        </div>

        <div className='mt-10'>
        <label>Image</label>

        <div className='companyedit__upload'>
                <IKContext publicKey="public_Nqch/XuW1fN+NARwogG4qD4gizs=" urlEndpoint="https://ik.imagekit.io/ivgrhmd4k" authenticationEndpoint={process.env.REACT_APP_API_BASE_URL + '/imagekit/auth'}>
                <IKUpload
                  fileName="logo.jpg"
                  tags={["sample-tag1", "sample-tag2"]}
                  customCoordinates={"10,10,10,10"}
                  isPrivateFile={false}
                  useUniqueFileName={true}
                  responseFields={["tags"]}
                  folder={"/Rewards"}
                  onError={onImageError} onSuccess={onImageSuccess}
                />
                </IKContext>
              </div>
          </div>

        <div className='mt-30'>
          <button className='btn' onClick={() => handleCreate()}>Add Reward</button>
        </div>
      </div>
      </>
    )}

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th>Name</th>
              <th>Description</th>
              <th>Type</th>
              <th></th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className=''>
                    <img src={screenshotUrl + 'tr:di-@@companylogos@@default-buyer.png/' + item.image_url} style={{height: '80px'}} />
                        </div>
                </td>

                <td>
                  {item.name}
                </td>

                <td>
                  {item.description}
                </td>

                {/* <td>
                  {Math.floor(parseInt(item.dollar_value) / costPerCredit)}
                </td> */}

                <td>
                  {item.type == 'gift_card' && (
                    <span className='admindashboard__green-flag'>Gift Card</span>
                  )}

                  {item.type == 'cash' && (
                    <span className='admindashboard__blue-flag'>Cash</span>
                  )}
                </td>

                <td>
                  <button className='btn'>Edit</button>

                  <button className='btn ml-10' onClick={() => handleDelete(item.point_reward_id)}>Delete</button>
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default PointRewards;