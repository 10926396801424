import React from 'react';
import {
  Box,
  Image,
  VStack,
  Text,
  Heading,
  HStack,
  Center,
} from '@chakra-ui/react';
import mobile from './homeAssets/mobileScreenshot.png';
import apple from './homeAssets/appleStoreBtn.png';
import googlePlay from './homeAssets/googleBtn.png';

const HeroAppMobile = () => {
  return (
    <Center>
      {/* Centering content vertically */}
      <Box mt='100px' px='30px' mb='30px'>
        <VStack align='center' spacing='20px'>
          {' '}
          {/* Setting the direction to 'column' and centering children */}
          <Heading
            fontFamily='poppins'
            fontSize={['28px', '28px', '40px']} // Responsive font size
            fontWeight='700'
            lineHeight={['36px', '36px', '50px']} // Responsive line height
            // mb='18px'
            textAlign='center' // Center heading on mobile
          >
            Introducing The LeadrPro Mobile App
          </Heading>
          <Image src={mobile} maxW='100%' mb='30px' />
          <Text
            fontSize='18px'
            fontWeight='600'
            lineHeight='23px'
            mb='17px'
            mt='100px'
            w={['90%', '90%', '642px']} // Responsive width
            fontFamily='poppins'
            textAlign='center' // Center text on mobile
          >
            AVAILABLE AT GOOGLE AND iOS APP STORE
          </Text>
          <Text
            fontFamily='poppins'
            fontSize='16px'
            fontWeight='400'
            lineHeight='29px'
            w={['90%', '90%', '640px']} // Responsive width
            textAlign='center' // Center text on mobile
          >
            Book demos, manage your calendar and schedule your payouts all in
            one convenient place... right from your phone! Introducing the brand
            new LeadrPro mobile app available on the Apple Store and Google Play
            now.
          </Text>
          <HStack pt='25px'>
            <a
              target='_blank'
              href='https://apps.apple.com/us/app/leadrpro/id6449265881'>
              <Image
                src={apple}
                w={['140px', '140px', '160px']} // Responsive width
                _hover={{ transform: 'scale(.97)' }}
              />
            </a>
            <a
              target='_blank'
              href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile'>
              <Image
                src={googlePlay}
                w={['140px', '140px', '160px']} // Responsive width
                _hover={{ transform: 'scale(.97)' }}
              />
            </a>
          </HStack>
        </VStack>
      </Box>
    </Center>
  );
};

export default HeroAppMobile;
