import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getMonthlyReport, getMonthlyReportByMonth } from '../../utils/api/admin';

function MonthlyReport() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getMonthlyReport();
    if (res.success){
      setData(res.data[0]);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getMonthlyReport();
      if (res.success){
        setData(res.data[0]);
        setLoaded(true);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  useEffect(async () => {
    if (month != null && year != null){
      let res = await getMonthlyReportByMonth(month, year);
      setData(res.data[0]);
      setLoaded(true);
    }

    else{
      loadData();
    }
  }, [month, year]);

  return (
    <>
      <Helmet>
        <title>Admin - Monthly Report</title>
      </Helmet>

      <h1>Monthly Report</h1>

      <p>
        Monthly stats for the current month.
      </p>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
          <div>
          <div className='admindashboard__filters' style={{display: 'flex'}}>
            <select className='input' onChange={(e) => setMonth(e.target.value)}>
              <option value={null}>Select month...</option>
              <option value={0}>January</option>
              <option value={1}>February</option>
              <option value={2}>March</option>
              <option value={3}>April</option>
              <option value={4}>May</option>
              <option value={5}>June</option>
              <option value={6}>July</option>
              <option value={7}>August</option>
              <option value={8}>September</option>
              <option value={9}>October</option>
              <option value={10}>November</option>
              <option value={11}>December</option>
            </select>

            <select className='input' onChange={(e) => setYear(e.target.value)}>
              <option value={null}>Select year...</option>
              <option value={2025}>2025</option>
              <option value={2024}>2024</option>
              <option value={2023}>2023</option>
              <option value={2022}>2022</option>
            </select>
          </div>

          <table className='admindashboard__table table'>
            <tr>
              <th></th>
              <th></th>
            </tr>

            <tr>
              <td>New buyers</td>
              <td>{data.new_buyer_count}</td>
            </tr>

            <tr>
              <td>New buyers - verified</td>
              <td>{data.new_buyer_count_verified}</td>
            </tr>

            <tr>
              <td>New buyers - verified with demos</td>
              <td>{data.new_buyer_count_verified_with_demos}</td>
            </tr>

            <tr>
              <td>New sellers</td>
              <td>{data.new_seller_count}</td>
            </tr>

            <tr>
              <td>Demo applications</td>
              <td>{data.demos_submitted}</td>
            </tr>

            <tr>
              <td>Demo applications - Approved</td>
              <td>{data.demos_submitted_approved}</td>
            </tr>

            <tr>
              <td>Demos submitted - new buyers</td>
              <td>{data.demos_submitted_new_buyers}</td>
            </tr>

            <tr>
              <td>Demos submitted - 2 month old buyers</td>
              <td>{data.demos_submitted_new_buyers_2months}</td>
            </tr>

            <tr>
              <td>Demos submitted - 3 month old buyers</td>
              <td>{data.demos_submitted_new_buyers_3months}</td>
            </tr>

            <tr>
              <td>Demos submitted - sellers</td>
              <td>{data.demos_submitted_by_sellers}</td>
            </tr>

            <tr>
              <td>Demos submitted - buyers</td>
              <td>{data.demos_submitted_by_buyers}</td>
            </tr>

            <tr>
              <td>Demos completed</td>
              <td>{data.demos_completed}</td>
            </tr>

            <tr>
              <td>Demos declined - seller</td>
              <td>{data.demos_declined_seller}</td>
            </tr>

            <tr>
              <td>Demos unreviewed - seller</td>
              <td>{data.demos_unreviewed}</td>
            </tr>
          </table>
        </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default MonthlyReport;