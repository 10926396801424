import React, { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { useLocation } from 'react-router-dom';
import {getCompanyProfileContentItem} from '../../../utils/api';
import ScrollToTop from '../../../components/layout/ScrollToTop';
import './PortfolioHeadquarters.css'; // Add CSS for styling
import Markdown from 'react-markdown';

const PortfolioHeadquarters = () => {
  const [blogTitle, setBlogTitle] = useState("");
  const [blogDate, setBlogDate] = useState("");
  const [fundingDetails, setFundingDetails] = useState({});
  const [blogContent, setBlogContent] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyInfo, setCompanyInfo] = useState("");
  const [companyDescription, setCompanyDescription] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [headquarters, setHeadquarters] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [otherLocations, setOtherLocations] = useState(""); 
  const [urlSlug, setUrlSlug] = useState("");

  const location = useLocation();
  const slug = location.pathname.split("/").pop(); // Extract the last segment of the path as the slug

  console.log("slug", slug);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCompanyProfileContentItem(slug);
        if (res.success){
          let data = res.data;

          setBlogTitle(data.title || "");
          setBlogDate(data.date_published || "");
          setFundingDetails(data.fundingDetails || {});
          setBlogContent(data.content || "");
          setCompanyInfo(data.companyInfo || "");
          setCompanyName(data.name || "");
          setCompanyDescription(data.description || "");
          setHeadquarters(data.headquarters || "");
          setCompanyAddress(data.company_address);
          setOtherLocations(data.other_locations);
          setUrlSlug(data.url_slug);
        }
      } catch (error) {
        console.error("Error fetching blog data:", error);
      }
    };

    if (slug) {
      fetchData();
    }
  }, [slug]);

  return (
    <div className="portfolio">
      <ScrollToTop />

      <Helmet>
            <title>{blogTitle}</title>
            <meta name="description" content={`${metaDescription}`} />
            <link rel="canonical" href={`https://www.leadrpro.com/portfolio/${urlSlug}`} />

          </Helmet>
      <div>
        <Link to='/portfolio'>Back</Link>
      </div>
      <h1 className="blog__title">{blogTitle}</h1>
      <p className="blog__date">Date: {new Date(blogDate).toLocaleDateString()}</p>
      
      <div className="blog__funding-card">
        {/* <div className="blog__funding-logo">
          <img src={fundingDetails.logo || "path/to/logo.png"} alt="Company Logo" />
        </div> */}
        <div className="blog__funding-details">
          <h2>{companyName}</h2>

          <p className="mt-4">
            <strong>Company Address:</strong> {companyAddress || "N/A"}
          </p>
        </div>
      </div>
      
      <div className="portfolio__content">
        <p>
          <Markdown>
            {companyDescription}
          </Markdown>
        </p>

        <p className="mt-4">
          <h2>Other Locations</h2>
          <Markdown>
            {otherLocations}
          </Markdown>
        </p>

        <p className="mt-4">
        <iframe src="https://embeds.beehiiv.com/5398001d-beee-4819-a456-e658fc24b45a" data-test-id="beehiiv-embed" width="100%" height="320" frameborder="0" scrolling="no"></iframe>
        </p>
      </div>

    </div>
  );
};

export default PortfolioHeadquarters;
