import React from 'react';
import { Image, Center, VStack, Text, Heading, Button } from '@chakra-ui/react';
import payment from './homeAssets/payment.png';
import cloud from './homeAssets/cloudIcon.png';
import signUp from './homeAssets/signUpIcon.png';

const HeroLeft = () => {
  return (
    <Center px='30px' mt='50px' mb='100px'>
      {' '}
      {/* Centering content vertically */}
      <VStack align='center' spacing='4'>
        <Image src={payment} maxW='100%' mb='20px' />
        {/* Setting the direction to 'column' and centering children */}
        <Heading
          fontFamily='poppins'
          fontSize={['28px', '28px', '42px']} // Responsive font size
          fontWeight='700'
          lineHeight={['36px', '36px', '50px']} // Responsive line height
          pb='30px'
          textAlign='center' // Center heading on mobile
        >
          Convenient Payouts
        </Heading>
        <Text
          textAlign='center'
          w={['90%', '90%', '520px']} // Responsive width
          fontFamily='poppins'
          fontSize='18px'
          fontWeight='400'
          lineHeight='32px'
          color='#1D191F'
          pb='30px'>
          After each completed demo your funds are made available for withdrawal
          via PayPal, Venmo, CashApp, Zelle, Amazon Gift Card or Visa. It's
          really that easy!
        </Text>
        <Button
          as='a'
          href='https://www.leadrpro.com/app'
          color='#FFF'
          w={['90%', '250px']} // Responsive width
          h='50px'
          p='10px 80px'
          fontFamily='poppins'
          borderRadius='68px'
          bg='#7107A8'
          _hover={{
            bg: 'purple.700',
            transform: 'scale(0.98)',
          }}>
          <Image src={cloud} w='28px' mr='10px' />
          Download app
        </Button>
        <Button
          as='a'
          href='https://www.leadrpro.com/signup'
          bg='#FFF'
          color='#7107A8'
          fontFamily='poppins'
          w={['90%', '250px']} // Responsive width
          h='50px'
          p='10px 80px'
          borderRadius='68px'
          border='1px solid #7107A8'
          _hover={{
            bg: 'grey.300',
            transform: 'scale(0.98)',
            border: '2.5px solid #7107A8',
          }}>
          <Image src={signUp} w='25px' mr='10px' />
          Sign up free
        </Button>
      </VStack>
      {/* Max width to make it responsive */}
    </Center>
  );
};

export default HeroLeft;
