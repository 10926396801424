import React from 'react';
import { Box, Flex, Image, Text, HStack } from '@chakra-ui/react';

const BuyerLogoMapMobile = () => {
  return (
    <Box mx={'0px'}>
      <Flex
        direction='column'
        boxSize='full'
        bg='white'
        p={0}
        alignItems='center'
        justifyContent='center'>
        <Text
          fontFamily='poppins'
          fontWeight='semibold'
          mb={10}
          fontSize={['40px', , '40px']}
          textAlign='center'>
          Join Our Community
        </Text>

        <Flex
          direction='column' // Change to column direction for mobile
          w='full'
          alignItems='center'
          justifyContent='center'>
            <div className='center'>
              <img src='https://ik.imagekit.io/ivgrhmd4k/logos_1920.648128da.jpg?updatedAt=1710624157032' alt='' style={{width: '80%'}} />
            </div>
        </Flex>
      </Flex>
    </Box>
  );
};

export default BuyerLogoMapMobile;
