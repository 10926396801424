import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminSearchUsers } from '../../utils/api';
import { adminPayouts, updatePayoutStatus, getCompanyProfiles } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaEnvelope, FaGlobe, FaRegEnvelope, FaTimes } from 'react-icons/fa';
import PortfolioCompaniesAdd from './PortfolioCompaniesAdd';
import PortfolioCompaniesEdit from './PortfolioCompaniesEdit';

function PortfolioCompanies() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [payouts, setPayouts] = useState([]);
  const [countPending, setCountPending] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem]  = useState(null);
  const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getCompanyProfiles();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }

      else{
        alert('failed to reload and retry');
      }
    }

    loadData();
  }, []);

  async function loadData(){
    let res = await getCompanyProfiles(200);

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }

    else{
      alert('failed to reload');
    }
  }

  async function handleSearch(){
    if (query.length === 0){
      loadData();
      return;
    }
    let res = await adminSearchUsers(query);

    if (res.success){
      setUsers(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminSearchUsers(query);

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }
  }

  async function handlePaid(id, reference_id){
    setSubmitting(true);
    setSelectedId(id);

    let res = await updatePayoutStatus(reference_id, id);

    if (res.success){
      await loadData();
      setSelectedId(null);
      setSubmitting(false);
    }

    else{
      alert('failed to reload and retry');
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Payouts</title>
      </Helmet>

      <h1>Portfolio Companies</h1>

      <div>
        <button className='btn' onClick={() => setShowAdd(true)}>Add Portfolio Company</button>
      </div>

      <div className='admindashboard__search-lookup'>
        <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} />

        <div className='admindashboard__search-lookup-btn'>
          <button className='btn' onClick={() => handleSearch()}>Search</button>
        </div>
      </div>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && data.length > 0 && (
         <div>
          <table className='table admindashboard__table'>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Website</th>
              <th>Headquarters</th>
              <th>Actions</th>
            </tr>

            {data.map((item, index) => (
              <tr>
                <td>
                  {item.company_profile_id}
                </td>
                <td>
                  <div className='flex items-center gap-2'>
                    {item.company_logo && item.company_logo !== '' && (
                      <div className="">
                        <img style={{maxHeight: '50px'}} src={imageUrl + 'company_profiles/' + item.company_logo} alt="logo found" />
                      </div>
                    )}

                    <div className='text-md font-bold'>
                      {item.name}
                    </div>
                  </div>
                </td>

                <td>
                  {item.short_description}
                </td>

                <td>
                  {item.website}
                </td>

                <td>
                  {item.headquarters}
                </td>

                <td>
                  <button className='btn' onClick={() => {
                    setSelectedId(item.company_profile_id);
                    setSelectedItem(item);
                    setShowEdit(true);
                  }}>Edit</button>
                </td>
              </tr>
            ))}
          </table>
        </div>
      )}

      {showAdd && (
        <div className='overlay' style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
          <div className='modal' style={{ width: '80%', marginLeft: '-40%', backgroundColor: '#fff', overflow: 'auto' }}>
            <div className='modal__close' onClick={() => setShowAdd(false)}>
              <FaTimes />
            </div>
            <PortfolioCompaniesAdd setShow={setShowAdd} loadData={loadData} />
          </div>
        </div>
      )}

      {showEdit && (
        <div className='overlay' style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
          <div className='modal' style={{ width: '80%', marginLeft: '-40%', backgroundColor: '#fff', overflow: 'auto' }}>
            <div className='modal__close' onClick={() => setShowEdit(false)}>
              <FaTimes />
            </div>
            <PortfolioCompaniesEdit id={selectedId} item={selectedItem} setShow={setShowEdit} loadData={loadData} />
          </div>
        </div>
      )}
    </>
  );
}

export default PortfolioCompanies;