import {
  Box,
  Image,
  VStack,
  Heading,
  Text,
  HStack,
  Button,
  Flex,
} from '@chakra-ui/react';
import mobile from './homeAssets/mobileTopHero.png';
import desktop from './homeAssets/desktopTopHero.png';
import headerapp from '../../assets/design/homepage/header-app.png';
import headerapp2 from '../../assets/design/homepage/header_app_1000.jpg';

const TopHeroMobile = () => {
  return (
    <Box overflowX='hidden' mb='150px'>
      <Flex mt='20px' alignItems='center' flexDirection='column'>
      <Box mt='27px' textAlign='center'>
          {/* Display both mobile and desktop images */}
          <Image src={headerapp2} w={['100%', '100%', '100%', '400px']} />
          {/* <Image src={mobile} w={['100%', '100%', '100%', '100px']} /> */}
        </Box>

        <VStack align='center'>
        <div>
              <div style={{marginTop: '30px'}}>
            <a href="https://www.producthunt.com/posts/leadrpro-for-events?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-leadrpro&#0045;for&#0045;events" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=431555&theme=light&period=daily" alt="LeadrPro&#0032;for&#0032;Events - The&#0032;networking&#0032;app&#0032;for&#0032;events | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
            </div>
              </div>

          <Heading
            px='20px'
            fontFamily='poppins'
            fontSize='28px'
            fontWeight='700'
            lineHeight='50px'
            pb='10px'
            w='100%'
            textAlign='center'>
            Tinder for Sales<br/>
            Swipe, Match and Meet
          </Heading>
          <Text
            textAlign='center'
            w='100%'
            fontSize='20px'
            fontFamily='poppins'
            fontWeight='400'
            lineHeight='32px'
            mx='27px'
            color='#1D191F'
            maxW='330px'
            pb='27px'>
            Join as a Seller and connect with 50k+ enterprise prospects. Or as a Buyer, and get paid to evaluate software.
          </Text>
          <VStack justifyContent='center'>
            <Button
              as='a'
              href='https://www.leadrpro.com/signup'
              color='#FFF'
              fontFamily='poppins'
              w='100%'
              h='50px'
              p='10px 60px'
              mb='15px'
              borderRadius='68px'
              bg='#7107A8'
              _hover={{
                bg: 'purple.700',
                transform: 'scale(0.98)',
              }}>
              Create Free Account
            </Button>
            <Button
              as='a'
              href='https://www.leadrpro.com/app'
              bg='#FFF'
              color='#7107A8'
              fontFamily='poppins'
              w='100%'
              h='50px'
              p='10px 20px'
              borderRadius='68px'
              border='1px solid #7107A8'
              _hover={{
                bg: 'grey.300',
                transform: 'scale(0.98)',
                border: '2.5px solid #7107A8',
              }}>
              Download App
            </Button>
          </VStack>
        </VStack>
      </Flex>
    </Box>
  );
};

export default TopHeroMobile;
