import "./styles/reset.css";
import "./styles/App.css";
import "./styles/forms.css";
import "./styles/spinner.css";
import "./styles/loading.css";
import "./styles/card.css";
import "./pages/home/Home.css";

import { lazy, Suspense, useEffect, useState } from "react";
import Home2 from './pages/home2/Home2';
import HomepageHub from "./pages/homepage/HomepageHub";
import HomepageHub_Software from "./pages/homepage/HomepageHub_Software";
import HomepageHub_Events from "./pages/homepage/HomepageHub_Events";
import HomepageHub_Vendor from "./pages/homepage/HomepageHub_Vendor";
import HomeSellers from "./pages/home3/SellersHome";
// import ExtensionHome from './pages/home3/ExtensionHome';
import BuyerResults from "./pages/search/BuyerResults";
import Footer from "./components/layout/Footer";
import Header from "./components/layout/Header";
import Login from "./pages/signup/Login";
// import Feedback from './pages/feedback/Feedback';
import CreateAccount from "./pages/signup/CreateAccount";
import CreateAccountSeller from "./pages/signup/CreateAccountSeller";
import CreateAccountEvent from "./pages/signup/CreateAccountEvent";
import Search from "./pages/search/Search";
import EmailVerification from "./pages/signup/EmailVerification";
// import RequireAuth from './RequireAuth';
import Account from "./pages/account/Account";
import AccountWrapper from "./pages/account/AccountWrapper";
// import Company from './pages/company/Company';
import Company2 from "./pages/company/Company2";
import ForgotPassword from "./pages/signup/ForgotPassword";
import ResetPassword from "./pages/signup/ResetPassword";
import Terms_S03012023 from "./pages/terms/Terms_S03012023";
import BrowseSellers from "./pages/search/BrowseSellers";
// import SellerConfirmation from './pages/search/SellerConfirmation';
// import BuyerConfirmation from './pages/search/BuyerConfirmation';
// import BuyerCongratulations from './pages/search/BuyerCongratulations';
import AccountSetup from "./pages/signup/AccountSetup";
// import Landing1 from './pages/landing/Landing1';
// import Landing2 from './pages/landing/Landing2';
import AccountSetupSeller from "./pages/signup/AccountSetupSeller";
import AccountSetupEvent from "./pages/signup/AccountSetupEvent";
import Confirmation from "./pages/signup/Confirmation";
import DemoConfirmation from "./pages/dashboard/DemoConfirmation";
import DemoMessage from "./pages/dashboard/DemoMessage";
import DemoMessages from "./pages/dashboard/DemoMessages";
import BuyerProfile from "./pages/dashboard/BuyerProfile";
import UserProfile from "./pages/userprofile/UserProfile";
import ReschedulePolicy from "./pages/policy/ReschedulePolicy";
import Waitlist from "./pages/dashboard/Waitlist";
import DemoInvite from "./pages/demoinvite/DemoInvite";
import DemoInviteWithQuestions from "./pages/demoinvite/DemoInviteWithQuestions";
import DemoInviteWithEmail from "./pages/demoinvite/DemoInviteWithEmail";
import DemoInviteForCompany from "./pages/demoinvite/DemoInviteForCompany";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import {
  createChannelTracking,
  getUserProfile,
  updateMailOutbox,
  getUserAvailablePoints
} from "./utils/api";
import BuyerDashboardNew from "./pages/dashboard/BuyerDashboardNew";
import SellerDashboardNew from "./pages/dashboard/SellerDashboardNew";
import Review from "./pages/review/Review";
import Privacy03012023 from "./pages/privacy/Privacy_03012023";
import ExtensionPrivacy from "./pages/privacy/ExtensionPrivacy";
import SavedBuyers from "./pages/saved/SavedBuyers";
// import BuyerProfile from './pages/profile/BuyerProfile';
import BuyerProfilePublic from "./pages/profile/BuyerProfilePublic";
// import TechStack from './pages/profile/TechStack';
import PricingBuyer from "./pages/pricing/pricingNew/PricingBuyer";
import SellSoftware from "./pages/pricing/pricingNew/SellSoftware";
import Payment from "./pages/payment/Payment";
import AddPaymentMethod from "./pages/payment/AddPaymentMethod";
// import AboutUs from './pages/about/AboutUs';
import { ChakraProvider } from "@chakra-ui/react";
// import PaymentStatusWrapper from './pages/payment/PaymentStatusWrapper';
// import SiteFeedback from './pages/feedback/SiteFeedback';
// import CharityLanding from './pages/charity/CharityLanding';
import queryString from "query-string";
// import FAQBuyerSeparate from './pages/pricing/pricingNew/FAQBuyerSeparate';
// import FAQSellerSeparate from './pages/pricing/pricingNew/FAQSellerSeparate';
import SellerInvite from "./pages/signup/SellerInvite";
import EventInvite from "./pages/signup/EventInvite";
// import FAQOrphaned from './pages/pricing/pricingNew/FAQOrphaned';
import CategoryLanding from "./pages/categoryPages/CategoryLanding";
import Policies from "./pages/policy/Policies";
import PublicBuyerProfile from "./pages/buyerprofile/BuyerProfile";
import Feed from "./pages/feed/Feed";
import CompanyFeed from "./pages/feed/CompanyFeed";
import JoinFeed from "./pages/feed/JoinFeed";
import FeedChannels from "./pages/feed/FeedChannels";
import LinkedIn from "./pages/linkedin/LinkedIn";
import { Helmet } from "react-helmet";
// import HomeSxsw from './pages/home3/HomeSxsw';
import SellerPricing from "./pages/pricing/pricingNew/SellerPricing";
// import TeamBuildingSoftware from './pages/categoryPages/TeamBuildingSoftware';
// import ProjectManagementSoftware from './pages/categoryPages/ProjectManagementSoftware';

// import RequestForProposalSoftware from './pages/categoryPages/RequestForProposalSoftware';
import CategoryMaster from "./pages/categoryPages/CategoryMaster";

import { getIP } from "./utils/api";
import Captcha from "./pages/search/Captcha";
// import StayScrappyAndScale from './pages/growthPlaybook/StayScrappyAndScale';
import BuyerLandingGoogleAds from "./pages/pricing/pricingNew/BuyerLandingGoogleAds";
import BuyerReschedule from "./pages/reschedule/BuyerReschedule";
import SellerRescheduleConfirm from "./pages/reschedule/SellerRescheduleConfirm";
import Home4 from "./pages/home4/Home4";
import SelectPlan from "./pages/plan/SelectPlan";
import PlanCheckout from "./pages/plan/PlanCheckout";
import Recommended from "./pages/recommended/Recommended";
import BuyerRecommend from "./pages/buyerrecommend/BuyerRecommend";
import SellerRecommend from "./pages/sellerrecommend/SellerRecommend";
import BrowseSellers2 from "./pages/search/BrowseSellers2";
import { ProductFruits } from "react-product-fruits";
import { refresh } from "./services/auth.service";
import AppHome from "./pages/app/AppHome";
// import Events from './pages/events/Events';
import EventDetails from "./pages/events/EventDetails";
import EventDetailsNew from "./pages/events/EventDetailsNew";
import Payouts from "./pages/account/Payouts";
import ContactForm from "./pages/contact/ContactForm";
import CompanyReviews from "./pages/company/CompanyReviews";
import EventDashboard from "./pages/events/EventDashboard";
import CreateEvent from "./pages/events/CreateEvent";
import EditEvent from "./pages/events/EditEvent";
import ManageEvent from "./pages/events/ManageEvent";
import MyEvents from "./pages/events/MyEvents";
import Redirect from "./pages/events/Redirect";
import DemoFeedback from "./pages/demofeedback/DemoFeedback";
import ManageRoles from "./components/manageroles/ManageRoles";
import Webinars from "./pages/webinars/Webinars";
import Category from "./pages/category/Category";
import CategoryDetails from "./pages/category/CategoryDetails";
import Homepage from "./pages/homepage/Homepage";
import EventHome from "./pages/events/EventHome";
import BlogHome from "./pages/contenthub/blog/BlogHome";
import BlogDetails from "./pages/contenthub/blog/BlogDetails";
import BlogDetailsTC from "./pages/contenthub/blog/BlogDetailsTC";
import Rewards from "./pages/rewards/Rewards";
import RedeemReward from "./pages/dashboard/RedeemReward";
import JoinNow from "./pages/joinnow/JoinNow";
import CompanyCards from './pages/search/CompanyCards';
import BrowseProducts from "./pages/search/BrowseProducts";
import SearchProducts from "./pages/search/SearchProducts";
import NewsPage from './pages/contenthub/news/NewsPage';
import LeadRecommendations from "./pages/leadrecommendations/LeadRecommendations";
import MyLeadFormSubmissions from './pages/leadrecommendations/LeadFormSubmissions';
import PriceLeads from "./pages/dashboard/PriceLeads";
import PriceLead from "./pages/dashboard/PriceLead";
import PriceLeadsPurchased from "./pages/dashboard/PriceLeadsPurchased";
import PortfolioHome from "./pages/portfolio/PortfolioHome";
import PortfolioDetails from "./pages/portfolio/PortfolioDetails";
import NotFound from "./pages/404";

function App() {
  const [access, setAccess] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [currentIp, setCurrentIp] = useState("");
  const [userInfo, setUserInfo] = useState(null);
  const [isUserReal, setIsUserReal] = useState(false);
  const [realCheckDone, setRealCheckDone] = useState(false);
  const [availablePoints, setAvailablePoints] = useState(0);

  const AboutUs = lazy(() => import("./pages/about/AboutUs"));
  const Extension = lazy(() => import("./pages/home3/ExtensionHome"));
  const HomeSxsw = lazy(() => import("./pages/home3/HomeSxsw"));
  const Feedback = lazy(() => import("./pages/feedback/Feedback"));
  const SiteFeedback = lazy(() => import("./pages/feedback/SiteFeedback"));
  const StayScrappyAndScale = lazy(() =>
    import("./pages/growthPlaybook/StayScrappyAndScale")
  );
  const MarketingAnalyticsSoftware = lazy(() =>
    import("./pages/categoryPages/MarketingAnalyticsSoftware")
  );
  const SalesTrainingSoftware = lazy(() =>
    import("./pages/categoryPages/SalesTrainingSoftware")
  );
  const AdvertisingSoftware = lazy(() =>
    import("./pages/categoryPages/AdvertisingSoftware")
  );
  const SalesCoachingSoftware = lazy(() =>
    import("./pages/categoryPages/SalesCoachingSoftware")
  );
  const ContractorManagementSoftware = lazy(() =>
    import("./pages/categoryPages/ContractorManagementSoftware")
  );
  const SalesEngagementSoftware = lazy(() =>
    import("./pages/categoryPages/SalesEngagementSoftware")
  );
  const DataVisualizationSoftware = lazy(() =>
    import("./pages/categoryPages/DataVisualizationSoftware")
  );
  const TalentManagementSoftware = lazy(() =>
    import("./pages/categoryPages/TalentManagementSoftware")
  );
  const GrowthPlaybookThankYouPage = lazy(() =>
    import("./pages/growthPlaybook/GrowthPlaybookThankYouPage")
  );
  const GrowthPlaybook = lazy(() =>
    import("./pages/growthPlaybook/GrowthPlaybook")
  );
  const CorporateWellnessSoftware = lazy(() =>
    import("./pages/categoryPages/CorporateWellnessSoftware")
  );
  const FAQOrphaned = lazy(() =>
    import("./pages/pricing/pricingNew/FAQOrphaned")
  );
  const FAQBuyerSeparate = lazy(() =>
    import("./pages/pricing/pricingNew/FAQBuyerSeparate")
  );
  const FAQSellerSeparate = lazy(() =>
    import("./pages/pricing/pricingNew/FAQSellerSeparate")
  );
  const PayrollSoftware = lazy(() =>
    import("./pages/categoryPages/PayrollSoftware")
  );
  const DataAsAServiceSoftware = lazy(() =>
    import("./pages/categoryPages/DataAsAServiceSoftware")
  );
  const RequestForProposalSoftware = lazy(() =>
    import("./pages/categoryPages/RequestForProposalSoftware")
  );
  const CloudInfrastructureMonitoringSoftware = lazy(() =>
    import("./pages/categoryPages/CloudInfrastructureMonitoringSoftware")
  );
  const OnlineAptSchedulingSoftware = lazy(() =>
    import("./pages/categoryPages/OnlineAptSchedulingSoftware")
  );
  const ExperienceManagementSoftware = lazy(() =>
    import("./pages/categoryPages/ExperienceManagementSoftware")
  );
  const InvoiceManagementSoftware = lazy(() =>
    import("./pages/categoryPages/InvoiceManagementSoftware")
  );
  const EquipmentRentalSoftware = lazy(() =>
    import("./pages/categoryPages/EquipmentRentalSoftware")
  );
  const ContractManagementSoftware = lazy(() =>
    import("./pages/categoryPages/ContractManagementSoftware")
  );
  const DnsSecuritySoftware = lazy(() =>
    import("./pages/categoryPages/DnsSecuritySoftware")
  );
  const SalesGamificationSoftware = lazy(() =>
    import("./pages/categoryPages/SalesGamificationSoftware")
  );
  const VideoInterviewingSoftware = lazy(() =>
    import("./pages/categoryPages/VideoInterviewingSoftware")
  );
  const InterviewSchedulingSoftware = lazy(() =>
    import("./pages/categoryPages/InterviewSchedulingSoftware")
  );
  const UserGeneratedContentSoftware = lazy(() =>
    import("./pages/categoryPages/UserGeneratedContentSoftware")
  );
  const EmployeeAdvocacySoftware = lazy(() =>
    import("./pages/categoryPages/EmployeeAdvocacySoftware")
  );
  const RecruitingAutomationSoftware = lazy(() =>
    import("./pages/categoryPages/RecruitingAutomationSoftware")
  );
  const FraudDetectionSoftware = lazy(() =>
    import("./pages/categoryPages/FraudDetectionSoftware")
  );
  const ProjectManagementSoftware = lazy(() =>
    import("./pages/categoryPages/ProjectManagementSoftware")
  );
  const TeamBuildingSoftware = lazy(() =>
    import("./pages/categoryPages/TeamBuildingSoftware")
  );
  const ManageAlerts = lazy(() => import("./pages/managealerts/ManageAlerts"));
  let rand1 = parseInt(Math.random() * 100);

  async function handleLoadPoints(){
    let res = await getUserAvailablePoints();

    if (res.success){
      setAvailablePoints(res.data);
    }
  }

  async function refreshPoints(){
    let res = await getUserAvailablePoints();

    if (res.success){
      setAvailablePoints(res.data);
    }
  }

  useEffect(async () => {
    async function loadUserProfile() {
      let res = await getUserProfile();

      if (res.success) {
        let data = res.data;
        let date_created = data.date_created;
        let role = "";

        if (data.is_buyer === true && data.is_seller === true) {
          role = "Buyer/Seller";
        } else if (data.is_buyer === true) {
          role = "Buyer";
        } else if (data.is_seller === true) {
          role = "Seller";
        }

        let obj = {
          username: data.email,
          firstname: data.first_name,
          role: role,
          signUpAt: date_created,
        };

        setUserInfo(obj);
      } else if (res.status === 403) {
        await refresh();

        let res = await getUserProfile();

        if (res.success) {
          let data = res.data;

          let obj = {
            username: data.email,
            firstname: data.first_name,
            role: data.is_buyer ? "Buyer" : "Seller",
          };

          setUserInfo(obj);
        }
      }
    }

    loadUserProfile();
    handleLoadPoints();
  }, []);

  function setLocalCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
  }

  function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  }

  useEffect(async () => {
    async function checkChannel() {
      const parsed = queryString.parse(window.location.search);
      let channel = "";
      let campaignid = "";
      let adid = "";

      if (parsed["channel"]) {
        channel = parsed["channel"];
      }

      if (parsed["campaignid"]) {
        campaignid = parsed["campaignid"];
      }

      if (parsed["adid"]) {
        adid = parsed["adid"];
      }

      let tok = getCookie("channel_token");

      if (campaignid == "defbgf_bddbhag_chlfe_bhevfadf") {
        return;
      }

      if (campaignid == "chlfeffaeteve") {
        return;
      }

      if (campaignid == "chlfeffaeteveavefggbhdu") {
        return;
      }

      if (campaignid == "chlfe_jbedb_gbetfgfe_bdgbcfe") {
        return;
      } else if (campaignid == "chlfe_cebvagehfg_gbetfgfe") {
        return;
      }

      if (campaignid == "BNL6") {
        return;
      }

      if (campaignid == "buyersendgridfirsttouch") {
        return;
      }

      if (campaignid == "dhfgbz-fzbvy") {
        return;
      }

      if (campaignid == "chlfeffaeteve_avefg_gbhdu") {
        return;
      }

      if (channel.length > 0 && campaignid.length > 0) {
        let res = await createChannelTracking(channel, campaignid, adid);

        if (res.success) {
          let token = res.data;
          setLocalCookie("channel_token", token, 365);
        }
      }
    }

    async function checkMailOutbox() {
      const parsed = queryString.parse(window.location.search);
      let moid = "";

      if (parsed["moid"]) {
        moid = parsed["moid"];
      }

      if (moid.length > 0) {
        let res = await updateMailOutbox(moid);

        if (res.success) {
          //let token = res.data;
          //setLocalCookie("channel_token", token, 365);
        }
      }
    }

    async function checkPartnerstack() {
      const parsed = queryString.parse(window.location.search);
      let partner_key = "";

      // if (parsed['gspk']) {
      //   partner_key = parsed['gspk'];
      //   setLocalCookie('ps_partner_key', partner_key, 365);
      // }

      //let tok = getCookie('channel_token');

      // if (channel.length > 0 && campaignid.length > 0) {
      //   let res = await createChannelTracking(channel, campaignid, adid);

      //   if (res.success) {
      //     let token = res.data;
      //     setLocalCookie('channel_token', token, 365);
      //   }
      // }
    }

    if (realCheckDone === true){
      await checkChannel();
      await checkPartnerstack();
      await checkMailOutbox();
    }
  }, [realCheckDone]);

  useEffect(() => {
    const handleMouseMove = () => {
      setIsUserReal(true);
      // Log the user as real (e.g., send to your server or analytics)
      //console.log('User is real');
      setRealCheckDone(true);
      // Remove the event listener after detecting mouse movement
      window.removeEventListener('mousemove', handleMouseMove);
    };

    // Add the event listener for mouse movement
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup function to remove the event listener if the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  if (access) {
    return (
      <div className="App">
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <ChakraProvider>
              <Header authenticated={authenticated} availablePoints={availablePoints} />
              <Helmet>
                <title>Software Discovery Tool | Home - LeadrPro</title>
                <meta name="description" content="App Description" />
              </Helmet>

              <div>
                <Routes>
                  <Route path='/homeabc' element={<Home2 />} />
                  {/* <Route path="/" element={<HomepageHub />} /> */}

                  {/* <Route path="/" element={<BrowseProducts />} /> */}
                  <Route path="/" element={<HomepageHub_Software />} />

                  <Route path="/sellers" element={<HomeSellers />} />

                  <Route path="/extension" element={<Extension />} />

                  <Route path='/home2023' element={<Home4 />} />

                  <Route path='/portfolio' element={<PortfolioHome />} />

                  <Route path='/portfolio/:slug' element={<PortfolioDetails />} />

                  <Route path='/404' element={<NotFound />} />

                  <Route
                    path="/account-setup"
                    element={
                      <AccountSetup setAuthenticated={setAuthenticated} />
                    }
                  />

                  <Route
                    path="/account-setup-s"
                    element={
                      <AccountSetupSeller setAuthenticated={setAuthenticated} />
                    }
                  />

                  <Route
                    path="/account-setup-e"
                    element={
                      <AccountSetupEvent setAuthenticated={setAuthenticated} />
                    }
                  />

                  <Route path="/signup" element={<CreateAccount />} />

                  <Route
                    path="/signup/seller"
                    element={<CreateAccountSeller />}
                  />

                  <Route
                    path="/signup/events"
                    element={<CreateAccountEvent />}
                  />

                  <Route
                    path="/search/sellers"
                    element={<Search userType="Seller" />}
                  />

                  <Route path="/" element={<AccountWrapper view={"prospect"} />}>
                  <Route
                    path="/search/buyers"
                    element={<Search userType="Buyer" />}
                  />
                  </Route>
                  {/* <Route path='/search/buyers' element={<BuyerSearch />} /> */}

                  <Route path="/forgot-password" element={<ForgotPassword />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/verify-email" element={<EmailVerification />} />

                  {/* <Route path='/company2/:id' element={<Company />} />
                <Route path='/company2/:id/:name' element={<Company />} /> */}

                  <Route path="/company/:id" element={<Company2 refreshPoints={refreshPoints} />} />
                  
                  <Route path="/company/:id/:name" element={<Company2 refreshPoints={refreshPoints} />} />

                  <Route path="/reviews/:id" element={<CompanyReviews />} />
                  <Route
                    path="/reviews/:id/:name"
                    element={<CompanyReviews />}
                  />

                  <Route path="/recommended/:id" element={<Recommended />} />

                  {/* <Route
                  path='/seller-confirmation'
                  element={<SellerConfirmation />}
                /> */}
                  {/* <Route
                  path='/buyer-confirmation'
                  element={<BuyerConfirmation />}
                /> */}

                  <Route
                    path="/signup/confirmation"
                    element={<Confirmation />}
                  />
                  <Route path="/confirmation" element={<DemoConfirmation />} />

                  <Route path="/" element={<AccountWrapper view={"inbox"} />}>
                    <Route
                      path="/dashboard/message/:refid"
                      element={<DemoMessage />}
                    />
                  </Route>

                  <Route path="/" element={<AccountWrapper view={"inbox"} />}>
                    <Route path="/dashboard/inbox" element={<DemoMessages />} />
                  </Route>

                  <Route path="/" element={<AccountWrapper view={"profile"} />}>
                    <Route
                      path="/dashboard/profile"
                      element={<BuyerProfile />}
                    />
                  </Route>

                  <Route
                    path="/"
                    element={<AccountWrapper view={"waitlist"} />}
                  >
                    <Route path="/dashboard/waitlist" element={<Waitlist />} />
                  </Route>

                  {/* <Route path="/search/sellers" element={<SellerResults/>} /> */}
                  {/* <Route path="/seller-filters" element={<SellerFilters/>} /> */}
                  <Route path="/search/buyers2" element={<BuyerResults />} />
                  {/* <Route path="/buyer-filters" element={<BuyerFilters/>} /> */}

                  <Route
                    path="/"
                    element={<AccountWrapper view={"sellerdashboard"} />}
                  >
                    <Route
                      path="/dashboard/seller"
                      element={<SellerDashboardNew />}
                    />
                  </Route>

                  <Route
                    path="/"
                    element={<AccountWrapper view={"buyerdashboard"} />}
                  >
                    <Route
                      path="/dashboard/buyer"
                      element={<BuyerDashboardNew refreshPoints={refreshPoints} />}
                    />
                  </Route>

                  <Route path="/" element={<AccountWrapper />}>
                    <Route path="/dashboard/redeem" element={<RedeemReward refreshPoints={refreshPoints} />} />
                  </Route>

                  <Route path="/" element={<AccountWrapper />}>
                    <Route path="/dashboard/price-leads" element={<PriceLeads />} />
                  </Route>

                  <Route path="/" element={<AccountWrapper />}>
                    <Route path="/dashboard/price-lead/:id" element={<PriceLead />} />
                  </Route>

                  <Route path="/" element={<AccountWrapper view='priceleads' />}>
                    <Route path="/dashboard/my-price-leads" element={<PriceLeadsPurchased />} />
                  </Route>

                  <Route path="/feedback" element={<Feedback />} />

                  {/* {rand1 >= 50 && (
                  <Route path='/demo/pending' element={<Landing1 />} />
                )}

                {rand1 < 50 && (
                  <Route path='/demo/pending' element={<Landing2 />} />
                )} */}

                  <Route path="/software3" element={<BrowseSellers refreshPoints={refreshPoints} />} />

                  <Route path="/software2" element={<CompanyCards refreshPoints={refreshPoints} />} />

                  {/* <Route path="/software3" element={<BrowseProducts refreshPoints={refreshPoints} />} /> */}

                  <Route path="/software" element={<SearchProducts refreshPoints={refreshPoints} />} />

                  <Route path="/discover" element={<BrowseSellers2 />} />

                  {/* <Route path='/landing1' element={<Landing1 />} />
                <Route path='/landing2' element={<Landing2 />} /> */}
                  <Route
                    path="/policy/reschedule"
                    element={<ReschedulePolicy />}
                  />

                  <Route path="/policies" element={<Policies />} />
                  <Route path="/login" element={<Login />} />

                  {/* <Route path='/pricing2' element={<Pricing2 />} /> */}

                  <Route path="/privacy" element={<Privacy03012023 />} />
                  <Route
                    path="/extension/privacy"
                    element={<ExtensionPrivacy />}
                  />
                  <Route path="/contact" element={<ContactForm />} />
                  <Route path="/terms" element={<Terms_S03012023 />} />
                  {/* <Route path='/privacy' element={<Privacy />} /> */}

                  <Route path="/saved/buyers" element={<SavedBuyers />} />

                  <Route path="/review/*" element={<Review />} />
                  <Route path="/account/*" element={<Account />} />

                  <Route path="/review/*" element={<Review />} />

                  {/* <Route path='/tech-stack' element={<TechStack />} /> */}

                  <Route
                    path="/profile3/:slug"
                    element={<PublicBuyerProfile />}
                  />

                  <Route path="/profile/:slug" element={<UserProfile />} />

                  <Route path="/" element={<AccountWrapper view="payouts" />}>
                    <Route
                      exact={true}
                      path="/account/payouts"
                      element={<Payouts />}
                    />
                  </Route>

                  <Route path="/pricing" element={<SellerPricing />} />
                  <Route path="/sell-software" element={<SellSoftware />} />

                  <Route path="/how-it-works" element={<PricingBuyer />} />

                  <Route
                    path="/discover-software"
                    element={<BuyerLandingGoogleAds />}
                  />

                  <Route path="/about" element={<AboutUs />} />

                  <Route path="/add-payment" element={<Payment />} />

                  <Route
                    path="/add-payment-method"
                    element={<AddPaymentMethod />}
                  />

                  {/* <Route
                  path='/payment/setup-complete'
                  element={<PaymentStatusWrapper />}
                /> */}

                  <Route
                    path="/profile/public"
                    element={<BuyerProfilePublic />}
                  />

                  <Route path="/demo-invite" element={<DemoInvite />} />
                  <Route
                    path="/demo-invite-q"
                    element={<DemoInviteWithQuestions />}
                  />
                  <Route
                    path="/demo-invite-e"
                    element={<DemoInviteWithEmail />}
                  />
                  <Route
                    path="/demo/:slug"
                    element={<DemoInviteForCompany />}
                  />

                  <Route path="/" element={<AccountWrapper />}>
                    <Route path="/leave-feedback" element={<SiteFeedback />} />
                  </Route>

                  <Route
                    path="/demos/reschedule"
                    element={<BuyerReschedule />}
                  />

                  <Route
                    path="/demos/confirm-reschedule"
                    element={<SellerRescheduleConfirm />}
                  />

                  {/* <Route path='/charity' element={<CharityLanding />} /> */}
                  <Route path="/faq-buyer" element={<FAQBuyerSeparate />} />
                  <Route path="/faq-seller" element={<FAQSellerSeparate />} />
                  <Route path="/join-team" element={<SellerInvite />} />
                  <Route path="/join-event" element={<EventInvite />} />
                  <Route path="/faq" element={<FAQOrphaned />} />

                  <Route path="/growth-playbook" element={<GrowthPlaybook />} />
                  <Route
                    path="/stay-scrappy-and-scale"
                    element={<StayScrappyAndScale />}
                  />

                  <Route
                    path="/growth-playbook-ty"
                    element={<GrowthPlaybookThankYouPage />}
                  />

                  <Route path="/app" element={<AppHome />} />

                  <Route
                    path="/software-categories"
                    element={<CategoryMaster />}
                  />
                  <Route
                    path="/software-categories"
                    element={<CategoryMaster />}
                  />
                  
                  <Route
                    path="/category-landing"
                    element={<CategoryLanding />}
                  />
                  <Route
                    path="/payroll-software"
                    element={<PayrollSoftware />}
                  />
                  <Route
                    path="/marketing-analytics-software"
                    element={<MarketingAnalyticsSoftware />}
                  />
                  <Route
                    path="/sales-training-software"
                    element={<SalesTrainingSoftware />}
                  />
                  <Route
                    path="/advertising-software"
                    element={<AdvertisingSoftware />}
                  />
                  <Route
                    path="/sales-coaching-software"
                    element={<SalesCoachingSoftware />}
                  />
                  <Route
                    path="/contractor-management-software"
                    element={<ContractorManagementSoftware />}
                  />
                  <Route
                    path="/sales-engagement-software"
                    element={<SalesEngagementSoftware />}
                  />
                  <Route
                    path="/data-visualization-software"
                    element={<DataVisualizationSoftware />}
                  />
                  <Route
                    path="/talent-management-software"
                    element={<TalentManagementSoftware />}
                  />
                  <Route
                    path="/corporate-wellness-software"
                    element={<CorporateWellnessSoftware />}
                  />
                  <Route
                    path="/data-as-a-service-software"
                    element={<DataAsAServiceSoftware />}
                  />
                  <Route
                    path="/request-for-proposal-software"
                    element={<RequestForProposalSoftware />}
                  />
                  <Route
                    path="/cloud-infrastructure-monitoring-software"
                    element={<CloudInfrastructureMonitoringSoftware />}
                  />
                  <Route
                    path="/online-appointment-scheduling-software"
                    element={<OnlineAptSchedulingSoftware />}
                  />
                  <Route
                    path="/experience-management-software"
                    element={<ExperienceManagementSoftware />}
                  />
                  <Route
                    path="/invoice-management-software"
                    element={<InvoiceManagementSoftware />}
                  />
                  <Route
                    path="/equipment-rental-software"
                    element={<EquipmentRentalSoftware />}
                  />
                  <Route
                    path="/contract-management-software"
                    element={<ContractManagementSoftware />}
                  />
                  <Route
                    path="/dns-security-software"
                    element={<DnsSecuritySoftware />}
                  />
                  <Route
                    path="/sales-gamification-software"
                    element={<SalesGamificationSoftware />}
                  />
                  <Route
                    path="/video-interviewing-software"
                    element={<VideoInterviewingSoftware />}
                  />
                  <Route
                    path="/interview-scheduling-software"
                    element={<InterviewSchedulingSoftware />}
                  />
                  <Route
                    path="/user-generated-content-software"
                    element={<UserGeneratedContentSoftware />}
                  />
                  <Route
                    path="/employee-advocacy-software"
                    element={<EmployeeAdvocacySoftware />}
                  />
                  <Route
                    path="/recruiting-automation-software"
                    element={<RecruitingAutomationSoftware />}
                  />
                  <Route
                    path="/fraud-detection-software"
                    element={<FraudDetectionSoftware />}
                  />
                  <Route
                    path="/project-management-software"
                    element={<ProjectManagementSoftware />}
                  />
                  <Route
                    path="/team-building-software"
                    element={<TeamBuildingSoftware />}
                  />

                  <Route path="/sxsw" element={<HomeSxsw />} />

                  <Route path="/" element={<AccountWrapper view={"feed"} />}>
                    <Route path="/feed" element={<Feed />} />
                    <Route
                      path="/feed/company/:slug"
                      element={<CompanyFeed />}
                    />
                    <Route path="/feed/:slug" element={<Feed />} />
                    <Route path="/join-feed" element={<JoinFeed />} />
                    <Route path="/feeds" element={<FeedChannels />} />
                  </Route>

                  <Route path="/linkedin/redirect" element={<LinkedIn />} />
                  <Route path="/security-check" element={<Captcha />} />

                  <Route
                    path="/select-plan"
                    element={<SelectPlan is_first_time={false} />}
                  />
                  <Route
                    path="/select-new-plan"
                    element={<SelectPlan is_first_time={true} />}
                  />

                  <Route
                    path="/select-plan-checkout"
                    element={<PlanCheckout />}
                  />
                  <Route path="/recommendations" element={<BuyerRecommend />} />
                  <Route
                    path="/s/recommendations"
                    element={<SellerRecommend />}
                  />

                  <Route path="/categories" element={<Category />} />
                  <Route path="/category/:slug" element={<CategoryDetails />} />

                  <Route path="/events" element={<MyEvents />} />
                  <Route path="/event2/:id/:name" element={<EventDetails />} />
                  <Route path="/event/:id/:name" element={<EventDetailsNew />} />

                  <Route path="/account/events" element={<EventDashboard />} />
                  <Route path="/create-event" element={<CreateEvent />} />
                  <Route path="/edit-event/:id" element={<EditEvent />} />
                  <Route path="/manage-event/:id" element={<ManageEvent />} />

                  <Route path="/e/:slug" element={<Redirect />} />

                  <Route
                    path="/demo-feedback/:refid"
                    element={<DemoFeedback />}
                  />
                  <Route path="/manage-roles" element={<ManageRoles />} />

                  <Route path="/webinars" element={<Webinars />} />
                  <Route path="/manage-alerts" element={<ManageAlerts />} />

                  <Route path='/homepage-hub' element={<HomepageHub />} />
                  <Route path="/homepage" element={<Homepage />} />
                  <Route path="/founders-roundtable" element={<EventHome /> } />
                  {/* <Route path='/blog' element={<BlogHome />} /> */}
                  <Route path='/blog' element={<NewsPage /> } />

                  <Route path='/blog/:slug' element={<BlogDetails />} />
                  <Route path='/blog2/:slug' element={<BlogDetailsTC />} />
                  <Route path='/software-hub' element={<HomepageHub_Software />} />
                  <Route path='/events-hub' element={<HomepageHub_Events />} />
                  <Route path='/vendor-hub' element={<HomepageHub_Vendor />} />


                  <Route path="/rewards" element={<Rewards />} />
                  {/* <Route path="/join-now" element={<JoinNow />} /> */}
                  <Route path="/join-now" element={<CreateAccount />} />


                  <Route path='/lead-recs' element={<LeadRecommendations />} />

                  <Route path="/" element={<AccountWrapper view={"feed"} />}>
                    <Route path='/lead-subs' element={<MyLeadFormSubmissions />} />
                  </Route>

                </Routes>
              </div>
            </ChakraProvider>
          </Suspense>
        </BrowserRouter>

        <Footer></Footer>
        {/* <Analytics /> */}
      </div>
    );
  } else {
    return <div></div>;
  }
}

export default App;
