import './TopHero.css';
import { useState, useEffect } from 'react';
import {
  Box,
  Image,
  VStack,
  Heading,
  Text,
  HStack,
  Button,
  Flex,
  Center,
} from '@chakra-ui/react';
import headerapp2 from '../../assets/design/homepage/header_app_1000.jpg';

const TopHero = () => {
  return (
    <>
      <Box overflowX='hidden' mb='60px'>
        <Center>
          <Flex mt='80px' alignItems='center'>
            <VStack align='start' maxW='450px' pl='100px' mr='80px'>
            <div>
              <div style={{marginTop: '30px'}}>
            <a href="https://www.producthunt.com/posts/leadrpro-for-events?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-leadrpro&#0045;for&#0045;events" target="_blank">
              <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=431555&theme=light&period=daily" alt="LeadrPro&#0032;for&#0032;Events - The&#0032;networking&#0032;app&#0032;for&#0032;events | Product Hunt" style={{width: "250px", height: "54px"}} width="250" height="54" /></a>
            </div>
              </div>

              <Heading
                fontFamily='poppins'
                fontSize='45px'
                fontWeight='700'
                lineHeight='50px'
                pb='30px'
                w='570px'>
                Tinder for Sales<br/>
                <div style={{fontSize: '38px'}}>
                Swipe, Match and Meet
                </div>
              </Heading>
              <Text
                textAlign='left'
                w='500px'
                fontSize='18px'
                fontFamily='poppins'
                fontWeight='400'
                lineHeight='32px'
                color='#1D191F'
                pb='30px'>
                Join as a Seller and connect with 50k+ enterprise prospects. Or as a Buyer, and get paid to evaluate software.
              </Text>
              <HStack>
                <Button
                  as='a'
                  href='https://www.leadrpro.com/signup'
                  color='#FFF'
                  fontFamily='poppins'
                  w='250px'
                  h='50px'
                  p='10px 80px'
                  borderRadius='68px'
                  bg='#7107A8'
                  _hover={{
                    bg: 'purple.700',
                    transform: 'scale(0.98)',
                  }}>
                  Create Free Account
                </Button>

                <Button
                  as='a'
                  href='https://www.leadrpro.com/app'
                  bg='#FFF'
                  color='#7107A8'
                  fontFamily='poppins'
                  w='250px'
                  h='50px'
                  p='10px 80px'
                  borderRadius='68px'
                  border='1px solid #7107A8'
                  _hover={{
                    bg: 'grey.300',
                    transform: 'scale(0.98)',
                    border: '2.5px solid #7107A8',
                  }}>
                  Download App
                </Button>
              </HStack>
            </VStack>

            <Box position='relative' ml='150px'>
              <Image src={headerapp2} />
              {/* <Image src={desktop} w='700px' />
              <Image
                src={mobile}
                w='150px'
                position='absolute'
                bottom='0'
                left='0'
              /> */}
            </Box>
          </Flex>
        </Center>
      </Box>
    </>
  );
};

export default TopHero;