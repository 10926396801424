import './HeroApp.css';
import {
  Box,
  Image,
  Center,
  VStack,
  Text,
  Heading,
  HStack,
} from '@chakra-ui/react';
import bg from './homeAssets/appBg.png';
import mobile from './homeAssets/mobileScreenshot.png';
import apple from './homeAssets/appleStoreBtn.png';
import googlePlay from './homeAssets/googleBtn.png';

const HeroApp = () => {
  return (
    <>
      <Box mt='127px' position='relative' width='auto' height='auto'>
        <Center>
          <Image
            src={bg}
            width='100%'
            height='100%'
            objectFit='cover'
            ml='40px'
          />
        </Center>

        <Box
          position='absolute'
          top='47%'
          left='45%'
          transform='translate(-50%, -50%)'>
          <HStack>
            <Image src={mobile} mr='100px' />
            <VStack align='start'>
              <Text
                fontSize='18px'
                fontWeight='600'
                lineHeight='23px'
                mb='17px'
                w='642px'
                fontFamily='poppins'>
                AVAILABLE AT GOOGLE AND iOS APP STORE
              </Text>
              <Heading
                mr='100px'
                fontFamily='poppins'
                fontSize='40px'
                fontWeight='700'
                lineHeight='50px'
                mb='18px'>
                Introducing The New LeadrPro Mobile App
              </Heading>
              <Text
                fontFamily='poppins'
                fontSize='16px'
                fontWeight='400'
                lineHeight='29px'
                w='640px'>
                Book demos, manage your calendar and schedule your payouts all
                in one convenient place... right from your phone! Introducing
                the brand new LeadrPro mobile app available on the Apple Store
                and Google Play now.
              </Text>
              <HStack pt='25px'>
                <a
                  target='_blank'
                  href='https://apps.apple.com/us/app/leadrpro/id6449265881'>
                  <Image
                    src={apple}
                    mr='15px'
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>

                <a
                  target='_blank'
                  href='https://play.google.com/store/apps/details?id=com.leadrpro.mobile'>
                  <Image
                    src={googlePlay}
                    w='160px'
                    _hover={{ transform: 'scale(.97)' }}
                  />
                </a>
              </HStack>
            </VStack>
          </HStack>
        </Box>
      </Box>
    </>
  );
};

export default HeroApp;
