import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getPointActivity, createPointActivity, getPointUserActivity,getPointTransactions } from '../../utils/api/admin';
import './PointActivity.css';

function PointLedger() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pointsEarned, setPointsEarned] = useState('');
  const [totalPoints, setTotalPoints]  =useState(0);
  const [costPerCredit, setCostPerCredit] = useState(.25);
  const [query, setQuery] = useState('');
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getPointTransactions();

    if (res.success){
      setData(res.data);
      let total = 0;

      for (let i = 0; i < res.data.length; i++){
        total += parseInt(res.data[i].points_earned);
      }

      setTotalPoints(total);
      setLoaded(true);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getPointTransactions();

      if (res.success){
        setData(res.data);

        let total = 0;

      for (let i = 0; i < res.data.length; i++){
        total += parseInt(res.data[i].points_earned);
      }

      setTotalPoints(total);

        setLoaded(true);
      }
    }

    loadData();
  }, []);

  async function handleCreate(){
    let res = await createPointActivity(name, description, pointsEarned);

    if (res.success){
      loadData();

      setName('');
      setDescription('');
      setPointsEarned('');
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Point Ledger</h1>

      <p>
        This page shows all point transactions for all users.
      </p>

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <div>
            <strong>Records found: {data.length}</strong>
          </div>

          <div>
            <input type='text' className='input' placeholder='Search...' value={query} onChange={(e) => setQuery(e.target.value)} />
          </div>

          <table className='admindashboard__table table'>
            <tr>
              <th>Date</th>
              <th>Activity</th>
              <th>User</th>
              <th>Transaction</th>
              <th>Points</th>
              <th>Old Balance</th>
              <th>New Balance</th>
              <th>$ Amount</th>
            </tr>

            {data.filter(x => 
              (query.length > 0 && 
                (x.registered_user_id == query || 
                 (x.first_name && x.first_name.toLowerCase().includes(query.toLowerCase())) || 
                 (x.last_name && x.last_name.toLowerCase().includes(query.toLowerCase()))
                )
              ) || query.length == 0
            ).map((item, index) => (
              <tr key={index}>
                <td>
                  {new Date(item.date_created).toLocaleString()}
                </td>

                <td>
                  {item.activity_name}
                  {item.reward_name}
                  {item.demo_id && (
                    <span>Demo ID: {item.demo_id}</span>
                  )}
                </td>

                <td>
                  <div>#{item.registered_user_id}</div>
                  <div>{item.first_name} {item.last_name}</div>
                  <div>{item.email}</div>
                </td>

                <td>
                  {item.transaction_type == 'Earn' && (
                    <span className='admindashboard__green-flag'>Earn</span>
                  )}

                  {item.transaction_type == 'Redeem' && (
                    <span className='admindashboard__blue-flag'>Redeemed</span>
                  )}
                </td>

                <td>
                  {item.transaction_type == 'Earn' && (
                    <span>+{item.points_earned}</span>
                  )}

                  {item.transaction_type == 'Redeem' && (
                    <span>-{item.number_of_points}</span>
                  )}
                </td>

                <td>
                  {item.current_balance}
                </td>

                <td>
                  {item.new_balance}
                </td>

                <td>
                  {item.dollar_amount && item.transaction_type == 'Redeem' && (
                    <span>${item.dollar_amount}</span>
                  )}

                  {item.transaction_type == 'Earn' && (
                    <span>
                      ${.25 * item.points_earned}
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default PointLedger;