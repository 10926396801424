import React, { useState, useRef, useEffect } from 'react';
import './MarkdownEditor.css';
import { Bold, Italic, Link, Image, List, ListOrdered, Quote } from 'lucide-react';
import Markdown from 'react-markdown';

const MarkdownEditor = ({ 
  content = '', 
  onChange = () => {}, 
  className = '' 
}) => {
  const [markdown, setMarkdown] = useState(content);
  const textareaRef = useRef(null);
  const fileInputRef = useRef(null);

  // Update internal state when content prop changes
  useEffect(() => {
    if (content !== markdown) {
      setMarkdown(content);
    }

    if (content == '' || content == undefined){
        setMarkdown('');
    }
  }, [content, markdown]);

  const insertText = (before, after = '') => {
    const textarea = textareaRef.current;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = markdown.substring(start, end);
    const newText = markdown.substring(0, start) + 
                   before + selectedText + after + 
                   markdown.substring(end);
    
    handleChange(newText);
    
    // Set cursor position after insertion
    setTimeout(() => {
      textarea.focus();
      textarea.setSelectionRange(
        start + before.length,
        end + before.length
      );
    }, 0);
  };

  const handleChange = (newValue) => {
    if (newValue !== markdown) {
      setMarkdown(newValue);
      onChange(newValue);
    }
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('image', file)
    
    const res = await fetch('/api/upload', {
      method: 'POST',
      body: formData
    })
    const { url } = await res.json()
    insertText(`![${file.name}](${url})`);
    
    // Reset file input
    fileInputRef.current.value = '';
  };

  const toolbarButtons = [
    {
      icon: <Bold className="w-4 h-4" />,
      action: () => insertText('**', '**'),
      tooltip: 'Bold'
    },
    {
      icon: <Italic className="w-4 h-4" />,
      action: () => insertText('*', '*'),
      tooltip: 'Italic'
    },
    {
      icon: <Link className="w-4 h-4" />,
      action: () => insertText('[', '](url)'),
      tooltip: 'Link'
    },
    {
      icon: <List className="w-4 h-4" />,
      action: () => insertText('- '),
      tooltip: 'Bullet List'
    },
    {
      icon: <ListOrdered className="w-4 h-4" />,
      action: () => insertText('1. '),
      tooltip: 'Numbered List'
    },
    {
      icon: <Quote className="w-4 h-4" />,
      action: () => insertText('> '),
      tooltip: 'Quote'
    }
  ];

  return (
    <div className={`${className}`}>
      <div className="border rounded-lg shadow-sm">
        <div className="flex items-center gap-2 p-2 border-b bg-gray-50">
          {toolbarButtons.map((button, index) => (
            <button
              key={index}
              onClick={button.action}
              className="p-2 hover:bg-gray-200 rounded-lg transition-colors"
              title={button.tooltip}
            >
              {button.icon}
            </button>
          ))}
          <label className="p-2 hover:bg-gray-200 rounded-lg transition-colors cursor-pointer">
            <Image className="w-4 h-4" />
            <input
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleImageUpload}
              ref={fileInputRef}
            />
          </label>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-4">
          <div className="space-y-2">
            <textarea
              ref={textareaRef}
              value={markdown}
              onChange={(e) => handleChange(e.target.value)}

              className="w-full h-96 p-4 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none font-mono whitespace-pre-wrap"
              placeholder="Write your markdown here..."
            />
          </div>
          
          <div className="space-y-2">
            <div className="markdown-content w-full h-96 p-4 border rounded-lg overflow-auto prose prose-sm">
              <Markdown components={{
                p: ({children}) => <p className="whitespace-pre-line">{children}</p>
              }}>
                {markdown}
              </Markdown>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarkdownEditor;