import './PortfolioDetails.css';
import { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser, refresh } from '../../services/auth.service';
import { useLocation } from 'react-router-dom';
import { saveSiteFeedback } from '../../utils/api';
import ScrollToTop from '../../components/layout/ScrollToTop';
import PortfolioHeadquarters from './components/PortfolioHeadquarters';

function PortfolioDetails() {
    const search = useLocation().search;
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feedbackText, setFeedbackText] = useState('');

    useEffect(() => {
        document.title = "LeadrPro - Submit Ticket";
    }, []);

    return (
        <div className="portfoliodetails">
          <ScrollToTop/>
          
          <Helmet>
            <meta name="description" content="" />
          </Helmet>

          <div className='portfolio__content'>
            <PortfolioHeadquarters />
          </div>
        </div>
    );
}

export default PortfolioDetails;