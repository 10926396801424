import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { adminSearchUsers } from '../../utils/api';
import { adminPayouts, updatePayoutStatus, getCompanyProfiles, getCompanyProfileContentTypes, getCompanyProfileContent, addCompanyProfileContentType, deleteCompanyProfileContentType, generateCompanyProfileContent } from '../../utils/api/admin';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaEnvelope, FaGlobe, FaRegEnvelope, FaTimes } from 'react-icons/fa';
import PortfolioCompaniesAdd from './PortfolioCompaniesAdd';
import PortfolioCompaniesEdit from './PortfolioCompaniesEdit';
import './PortfolioContent.css';

function PortfolioContent() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [users, setUsers] = useState([]);
  const [query, setQuery] = useState('');
  const [payouts, setPayouts] = useState([]);
  const [countPending, setCountPending] = useState(0);
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [data, setData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedItem, setSelectedItem]  = useState(null);
  const [contentTypes, setContentTypes] = useState([]);
  const [content, setContent] = useState([]);

  const [typeName, setTypeName] = useState('');
  const [typeDescription, setTypeDescription] = useState('');
  const [typeTitlePattern, setTypeTitlePattern] = useState('');
  const [typeSlugPattern, setTypeSlugPattern] = useState('');
  const [typeMetaDescriptionPattern, setTypeMetaDescriptionPattern] = useState('');

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [deleteTypeId, setDeleteTypeId] = useState(null);

  const imageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadTypes(){
    let res = await getCompanyProfileContentTypes();

    if (res.success){
      setContentTypes(res.data);
    }

    else{
      alert('failed to reload and retry');
    }
  }

  async function loadContent(){
    let res = await getCompanyProfileContent();

    if (res.success){
      setContent(res.data);
    }

    else{
      alert('failed to reload and retry');
    }
  }
  
  useEffect(() => {
    async function loadData(){
      let res = await getCompanyProfiles();

      if (res.success){
        setData(res.data);
        setLoaded(true);
      }

      else{
        alert('failed to reload and retry');
      }
    }

    async function loadTypes(){
      let res = await getCompanyProfileContentTypes();

      if (res.success){
        setContentTypes(res.data);
      }

      else{
        alert('failed to reload and retry');
      }
    }

    async function loadContent(){
      let res = await getCompanyProfileContent();

      if (res.success){
        setContent(res.data);
      }

      else{
        alert('failed to reload and retry');
      }
    }

    loadData();
    loadTypes();
    loadContent();
  }, []);

  async function loadData(){
    let res = await getCompanyProfiles(200);

    if (res.success){
      setData(res.data);
      setLoaded(true);
    }

    else{
      alert('failed to reload');
    }
  }

  async function handleSearch(){
    if (query.length === 0){
      loadData();
      return;
    }
    let res = await adminSearchUsers(query);

    if (res.success){
      setUsers(res.data);
      setLoaded(true);
    }

    else if (res.status === 403){
      await refresh();

      let res = await adminSearchUsers(query);

      if (res.success){
        setUsers(res.data);
        setLoaded(true);
      }
    }
  }

  async function handleCreateType(){
    let data = {
      name: typeName,
      description: typeDescription,
      title_pattern: typeTitlePattern,
      slug_pattern: typeSlugPattern,
      meta_description_pattern: typeMetaDescriptionPattern
    };

    let res = await addCompanyProfileContentType(data);

    if (res.success){
      await loadTypes();
    }

    else{
      alert('failed to reload and retry');
    }
  }

  async function handleDeleteType() {
    if (!deleteTypeId) return;

    // Replace with actual API call to delete the content type
    let res = await deleteCompanyProfileContentType(deleteTypeId);

    if (res.success) {
      alert('Content type deleted successfully!');
      setShowDeleteConfirmation(false);
      setDeleteTypeId(null);
      await loadTypes();
    } else {
      alert('Failed to delete content type.');
    }
  }

  async function handleGenerate(typeId) {
    let res = await generateCompanyProfileContent(typeId);

    if (res.success) {
      alert('Content generated successfully!');
      await loadContent();
    } else {
      alert('Failed to generate content.');
    }
  }

  return (
    <>
      <Helmet>
        <title>Admin - Payouts</title>
      </Helmet>

      <h1>Portfolio Content</h1>

      <div>
        <h2>Content Types</h2>
        <table className='table admindashboard__table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th>Title Pattern</th>
              <th>Slug Pattern</th>
              <th>Meta Description Pattern</th>
              <th>Total Content</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {contentTypes.map((type) => (
              <tr key={type.company_profile_content_type_id}>
                <td>
                  {new Date(type.date_created).toLocaleDateString()}
                </td>

                <td>{type.company_profile_content_type_id}</td>
                <td>{type.name}</td>
                <td>{type.description}</td>
                <td>{type.title_pattern}</td>
                <td>{type.slug_pattern}</td>
                <td>{type.meta_description_pattern}</td>
                <td>
                  {type.content_count}
                </td>
                <td>
                  <button
                    className='link'
                    onClick={() => {
                      setDeleteTypeId(type.company_profile_content_type_id);
                      setShowDeleteConfirmation(true);
                    }}
                  >
                    Delete
                  </button>

                  <button className='link' onClick={() => handleGenerate(type.company_profile_content_type_id)}>
                    Generate Content
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h2>Create New Content Type</h2>

          <div className="flex flex-col">
            <label htmlFor="name" className="font-medium mb-1">Name:</label>
            <input type="text" id="name" name="name" required className="border rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300" value={typeName} onChange={(e) => setTypeName(e.target.value)} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="description" className="font-medium mb-1">Description:</label>
            <textarea id="description" name="description" required className="border rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300" value={typeDescription} onChange={(e) => setTypeDescription(e.target.value)}></textarea>
          </div>
          <div className="flex flex-col">
            <label htmlFor="title_pattern" className="font-medium mb-1">Title Pattern:</label>
            <input type="text" id="title_pattern" name="title_pattern" required className="border rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300" value={typeTitlePattern} onChange={(e) => setTypeTitlePattern(e.target.value)} />
          </div>
          <div className="flex flex-col">
            <label htmlFor="slug_pattern" className="font-medium mb-1">Slug Pattern:</label>
            <input type="text" id="slug_pattern" name="slug_pattern" required className="border rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300" value={typeSlugPattern} onChange={(e) => setTypeSlugPattern(e.target.value)} />
          </div>

          <div className="flex flex-col">
            <label htmlFor="slug_pattern" className="font-medium mb-1">Meta Description Pattern:</label>
            <input type="text" id="slug_pattern" name="slug_pattern" required className="border rounded px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300" value={typeMetaDescriptionPattern} onChange={(e) => setTypeMetaDescriptionPattern(e.target.value)} />
          </div>

          <button type="submit" className="btn bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600" onClick={() => handleCreateType()}>Create</button>
      </div>

      <div className='admindashboard__search-lookup'>
        <input type='text' placeholder='Search...' className='input' value={query} onChange={(e) => setQuery(e.target.value)} />

        <div className='admindashboard__search-lookup-btn'>
          <button className='btn' onClick={() => handleSearch()}>Search</button>
        </div>
      </div>

      <div>
        <h2>Content - {content.length} </h2>
        <table className='table admindashboard__table'>
  <thead>
    <tr>
      <th>Created</th>
      <th>Title</th>
      <th>URL Slug</th>
      <th>Date Published</th>
      <th>Status</th>
      <th>Content Type ID</th>
      <th></th>
    </tr>
  </thead>
  <tbody>

    {/* dynamic rows here */}
    {content.map((item) => (
      <tr key={item.id}>
        <td>
          {new Date(item.date_created).toLocaleDateString()}
        </td>
        <td>{item.title}</td>
        <td>{item.url_slug}</td>
        <td>{new Date(item.date_published).toLocaleDateString()}</td>
        <td>{item.status}</td>
        <td>{item.company_profile_content_type_id}</td>

        <td>
          <Link to={`/portfolio/${item.url_slug}`} target='_blank' className='link'>View</Link>
        </td>
      </tr>
    ))}
  </tbody>
</table>

      </div>


      {showAdd && (
        <div className='overlay' style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
          <div className='modal' style={{ width: '80%', marginLeft: '-40%', backgroundColor: '#fff', overflow: 'auto' }}>
            <div className='modal__close' onClick={() => setShowAdd(false)}>
              <FaTimes />
            </div>
            <PortfolioCompaniesAdd setShow={setShowAdd} loadData={loadData} />
          </div>
        </div>
      )}

      {showEdit && (
        <div className='overlay' style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
          <div className='modal' style={{ width: '80%', marginLeft: '-40%', backgroundColor: '#fff', overflow: 'auto' }}>
            <div className='modal__close' onClick={() => setShowEdit(false)}>
              <FaTimes />
            </div>
            <PortfolioCompaniesEdit id={selectedId} item={selectedItem} setShow={setShowEdit} loadData={loadData} />
          </div>
        </div>
      )}

      {showDeleteConfirmation && (
        <div className='overlay' style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
          <div className='modal' style={{ width: '400px', margin: '100px auto', backgroundColor: '#fff', padding: '20px', borderRadius: '8px', textAlign: 'center' }}>
            <h3 className='text-lg font-bold mb-4'>Confirm Deletion</h3>
            <p>Are you sure you want to delete this content type?</p>
            <div className='mt-4 flex justify-center gap-4'>
              <button
                className='btn bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600'
                onClick={handleDeleteType}
              >
                Delete
              </button>
              <button
                className='btn bg-gray-300 text-black px-4 py-2 rounded hover:bg-gray-400'
                onClick={() => setShowDeleteConfirmation(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default PortfolioContent;