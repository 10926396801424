import {
  Box,
  HStack,
  Heading,
  Text,
  VStack,
  Image,
  Avatar,
  Center,
  Spacer,
  Flex,
} from '@chakra-ui/react';
import star from './homeAssets/star.png';
import lyft from './homeAssets/lyft-avatar.png';
import gusto from './homeAssets/gusto.png';
import zapier from './homeAssets/zapier.png';

const Testimonial = () => {
  return (
    <>
      <Box>
        <Center mt={28}>
          <VStack>
            <Heading
              fontFamily='poppins'
              fontSize='42px'
              fontWeight='700'
              lineHeight='50px'
              pb='15px'>
              HEAR WHAT OUR USERS HAVE TO SAY
            </Heading>
            <Text
              fontFamily='poppins'
              color='#1D191F'
              fontSize='18px'
              fontWeight='400'
              lineHeight='32px'>
              Thousands of users and counting! Hear what our clients have to say
              about us!
            </Text>
          </VStack>
        </Center>

        <Box mt='60px' mx='135px' mb='200px'>
          <HStack>
            <section id='card1' className='home__testimonial-card'>
              <Box
                border='1px solid rgba(220, 226, 238, 0.71)'
                bg='#FFF'
                borderRadius='20px'
                px='30px'
                py='30px'>
                <VStack>
                  <Flex dir='row' justifyContent='center' maxW='25px' py='20px'>
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                  </Flex>

                  <Text
                    textAlign='center'
                    pb='20px'
                    fontFamily='poppins'
                    fontSize='16px'>
                    Paying me for my attention?! I love it! A great motivator
                    for discovering new software for my business.
                  </Text>
                  <HStack>
                    <Avatar size='lg' name='zapier' src={zapier} mr={2} />
                    <VStack>
                      <Heading
                        as='h4'
                        size='md'
                        fontFamily='poppins'
                        fontSize='20px'>
                        VP Of Product
                      </Heading>

                      <Text fontFamily='poppins' fontSize='16px'>
                        Zapier
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
              </Box>
            </section>

            <section id='card2' className='home__testimonial-card'>
              <Box
                border='1px solid rgba(220, 226, 238, 0.71)'
                bg='#FFF'
                borderRadius='20px'
                px='30px'
                py='30px'>
                <VStack>
                  <Flex dir='row' justifyContent='center' maxW='25px' py='20px'>
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                  </Flex>

                  <Text
                    textAlign='center'
                    pb='20px'
                    fontFamily='poppins'
                    fontSize='16px'>
                    An absolute game-changer amidst the overwhelming digital
                    chaos. LeadrPro is the tool I didn't know I needed!
                  </Text>
                  <HStack>
                    <Avatar size='lg' name='lyft' src={lyft} mr={2} />
                    <VStack>
                      <Heading
                        as='h4'
                        size='md'
                        fontSize='20px'
                        fontFamily='poppins'>
                        HR Director
                      </Heading>
                      <Text fontSize='16px' fontFamily='poppins'>
                        Lyft
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
              </Box>
            </section>

            <section id='card3' className='home__testimonial-card'>
              <Box
                border='1px solid rgba(220, 226, 238, 0.71)'
                bg='#FFF'
                borderRadius='20px'
                px='30px'
                py='30px'>
                <VStack>
                  <Flex dir='row' justifyContent='center' maxW='25px' py='20px'>
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                    <Image src={star} />
                  </Flex>

                  <Text
                    textAlign='center'
                    pb='20px'
                    fontFamily='poppins'
                    fontSize='16px'>
                    Not only did I discover groundbreaking software with
                    LeadrPro, but I also got paid for my time! It's a win-win
                    situation.
                  </Text>
                  <HStack>
                    <Avatar size='lg' name='gusto' src={gusto} mr={2} />
                    <VStack>
                      <Heading
                        as='h4'
                        size='md'
                        fontSize='20px'
                        fontFamily='poppins'>
                        VP Of Marketing
                      </Heading>
                      <Text fontSize='16px' fontFamily='poppins'>
                        Gusto
                      </Text>
                    </VStack>
                  </HStack>
                </VStack>
              </Box>
            </section>
          </HStack>
        </Box>
      </Box>
    </>
  );
};

export default Testimonial;
