import {
  Box,
  Image,
  Center,
  HStack,
  VStack,
  Text,
  Heading,
  Button,
  Spacer,
} from '@chakra-ui/react';
import payment from './homeAssets/payment.png';
import cloud from './homeAssets/cloudIcon.png';
import signUp from './homeAssets/signUpIcon.png';

const HeroLeft = () => {
  return (
    <>
      <Box mx='200px' mt='100px'>
        <Center>
          <HStack>
            <VStack align='start' pr='120px'>
              <Heading
                fontFamily='poppins'
                fontSize='42px'
                fontWeight='700'
                lineHeight='50px'
                pb='30px'>
                Convenient Payouts
              </Heading>
              <Text
                textAlign='left'
                w='520px'
                fontFamily='poppins'
                fontSize='18px'
                fontWeight='400'
                lineHeight='32px'
                color='#1D191F'
                pb='30px'>
                After each completed demo your funds are made available for
                withdrawal via PayPal, Venmo, CashApp, Zelle, Amazon Gift Card
                or Visa. It's really that easy!
              </Text>
              <HStack>
                <Button
                  as='a'
                  href='https://www.leadrpro.com/app'
                  color='#FFF'
                  w='250px'
                  h='50px'
                  p='10px 80px'
                  fontFamily='poppins'
                  borderRadius='68px'
                  bg='#7107A8'
                  _hover={{
                    bg: 'purple.700',
                    transform: 'scale(0.98)',
                  }}>
                  <Image src={cloud} w='28px' mr='10px' />
                  Download app
                </Button>
                <Button
                  as='a'
                  href='https://www.leadrpro.com/signup'
                  bg='#FFF'
                  color='#7107A8'
                  fontFamily='poppins'
                  w='250px'
                  h='50px'
                  p='10px 80px'
                  borderRadius='68px'
                  border='1px solid #7107A8'
                  _hover={{
                    bg: 'grey.300',
                    transform: 'scale(0.98)',
                    border: '2.5px solid #7107A8',
                  }}>
                  <Image src={signUp} w='25px' mr='10px' />
                  Sign up free
                </Button>
              </HStack>
            </VStack>
            <Spacer />
            <Image src={payment} w='300px' />
          </HStack>
        </Center>
      </Box>
    </>
  );
};

export default HeroLeft;
