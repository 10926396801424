import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { getCompaniesForSitemap, getBlogAll } from '../../utils/api';
import {getCompanyProfileContent} from '../../utils/api/admin';

function Actions() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [sitemap, setSitemap] = useState('');
  const [sitemapGenerated, setSitemapGenerated] = useState(false);
  const [copied,setCopied] = useState(false);
  const [sitemapCount, setSitemapCount] = useState(0);
  const [content, setContent] = useState([]);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  useEffect(() => {
    async function loadData(){
      let res = await getCompanyProfileContent(1);

      if (res.success){
        setContent(res.data);
      }
    }

    loadData();
  }, []);

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleGenerateSitemapProfile(){
    let output = '';

    let _content = content.filter(x => x.status =='published').map(item => {
      let path = `https://www.leadrpro.com/portfolio/${item.url_slug}`;
      let priority = '1.0';
      return {path, priority};
    });

    _content.forEach(({ path, lastmod, changefreq, priority }) => {
      output += `<url>\n<loc>${path}</loc>\n`;
        if (lastmod) {
          output += `<lastmod>${lastmod}</lastmod>\n`;
        }
        if (changefreq) {
          output += `<changefreq>${changefreq}</changefreq>\n`;
        }
        if (priority) {
          output += `<priority>${priority}</priority>\n`;
        }
        output += `</url>\n`;
    });

    setSitemap(output);
    setSitemapGenerated(true);
  }

  async function generateSitemap() {
    let output = '';

    let res = await getCompaniesForSitemap();

    if (res.success){
      let _companies = res.data.map(item => {
        let path = `https://www.leadrpro.com/company/${item.company_id}/${item.url_slug}`;
        let priority = '1.0';
        return {path, priority};
      });

      setSitemapCount(_companies.length);

      const baseUrl = 'https://www.leadrpro.com';
      output = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

    _companies.forEach(({ path, lastmod, changefreq, priority }) => {
      output += `<url>\n<loc>${path}</loc>\n`;
        if (lastmod) {
          output += `<lastmod>${lastmod}</lastmod>\n`;
        }
        if (changefreq) {
          output += `<changefreq>${changefreq}</changefreq>\n`;
        }
        if (priority) {
          output += `<priority>${priority}</priority>\n`;
        }
        output += `</url>\n`;
    });

    output += '</urlset>';
    }

    else if (res.status === 403){
      await refresh();

      let res = await getCompaniesForSitemap();

      if (res.success){
        let _companies = res.data.map(item => {
          let path = `https://www.leadrpro.com/company/${item.company_id}/${item.url_slug}`;
          let priority = '1.0';
          return {path, priority};
        });

        let _reviews = res.data.map(item => {
          let path = `https://www.leadrpro.com/reviews/${item.company_id}/${item.url_slug}`;
          let priority = '0.9';
          return {path, priority};
        });

        setSitemapCount(_companies.length + _reviews.length);
  
        output = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;
  
      _companies.forEach(({ path, lastmod, changefreq, priority }) => {
        output += `<url>\n<loc>${path}</loc>\n`;
          if (lastmod) {
            output += `<lastmod>${lastmod}</lastmod>\n`;
          }
          if (changefreq) {
            output += `<changefreq>${changefreq}</changefreq>\n`;
          }
          if (priority) {
            output += `<priority>${priority}</priority>\n`;
          }
          output += `</url>\n`;
      });

      _reviews.forEach(({ path, lastmod, changefreq, priority }) => {
        output += `<url>\n<loc>${path}</loc>\n`;
          if (lastmod) {
            output += `<lastmod>${lastmod}</lastmod>\n`;
          }
          if (changefreq) {
            output += `<changefreq>${changefreq}</changefreq>\n`;
          }
          if (priority) {
            output += `<priority>${priority}</priority>\n`;
          }
          output += `</url>\n`;
      });
  
      output += '</urlset>';
      }
    }

    return output;
};

async function generateSitemapForBlog() {
  let output = '';

  let res = await getBlogAll();

  console.log('res', res);
  if (res.success == true){
    console.log('res', res.data);
    let _companies = res.data.map(item => {
      let path = `https://www.leadrpro.com/blog/${item.slug}`;
      let priority = '1.0';
      return {path, priority};
    });

    setSitemapCount(_companies.length);

    const baseUrl = 'https://www.leadrpro.com';
    output = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

  _companies.forEach(({ path, lastmod, changefreq, priority }) => {
    output += `<url>\n<loc>${path}</loc>\n`;
      if (lastmod) {
        output += `<lastmod>${lastmod}</lastmod>\n`;
      }
      if (changefreq) {
        output += `<changefreq>${changefreq}</changefreq>\n`;
      }
      if (priority) {
        output += `<priority>${priority}</priority>\n`;
      }
      output += `</url>\n`;
  });

  output += '</urlset>';
  }

  else if (res.status === 403){
    await refresh();

    let res = await getBlogAll();

    if (res.success){
      let _companies = res.data.edges.map(item => {
        let path = `https://www.leadrpro.com/company/${item.node.slug}`;
        let priority = '1.0';
        return {path, priority};
      });

      setSitemapCount(_companies.length);

      output = `<?xml version="1.0" encoding="UTF-8"?>\n<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">\n`;

    _companies.forEach(({ path, lastmod, changefreq, priority }) => {
      output += `<url>\n<loc>${path}</loc>\n`;
        if (lastmod) {
          output += `<lastmod>${lastmod}</lastmod>\n`;
        }
        if (changefreq) {
          output += `<changefreq>${changefreq}</changefreq>\n`;
        }
        if (priority) {
          output += `<priority>${priority}</priority>\n`;
        }
        output += `</url>\n`;
    });

    output += '</urlset>';
    }
  }

  return output;
};

  async function handleGenerateSitemap(){
    let res = await generateSitemap();
  
    setSitemap(res);
    setSitemapGenerated(true);
  }
  
  async function handleGenerateSitemapBlog(){
    let res = await generateSitemapForBlog();
  
    setSitemap(res);
    setSitemapGenerated(true);
  }

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  async function handleCreateDemoAsSeller(){

  }

  async function handleCreateDemoAsBuyer(){
    
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Actions</h1>

      <div>
        <div style={{display: 'flex'}}>
          <div className='admindashboard__actions'>
            <div className='admindashboard__action'>
              <strong>Generate company sitemap:</strong>
              <span><button className='btn' onClick={() => handleGenerateSitemap()}>Generate</button></span>
            </div>

            <div className='admindashboard__action'>
              <strong>Generate blog sitemap:</strong>
              <span><button className='btn' onClick={() => handleGenerateSitemapBlog()}>Generate</button></span>
            </div>

            <div className='admindashboard__action'>
              <strong>Generate Company Profile Content Sitemap:</strong>
              <span><button className='btn' onClick={() => handleGenerateSitemapProfile()}>Generate</button></span>
            </div>

            <div className='admindashboard__action'>
              <strong>Create test demo - as seller:</strong>
              <span><button className='btn' onClick={() => handleCreateDemoAsSeller()}>Create</button></span>
            </div>

            <div className='admindashboard__action'>
              <strong>Create test demo - as buyer:</strong>
              <span><button className='btn' onClick={() => handleCreateDemoAsBuyer()}>Create</button></span>
            </div>
          </div>

          <div className='admindashboard__actions-output'>
            {sitemapGenerated && (
              <>
              <CopyToClipboard text={sitemap} onCopy={handleCopy}>
                <button className='buyerprofile__copy-link'>{copied ? 'Copied!' : 'Copy'}</button>
              </CopyToClipboard>

              <div>
                <strong>Count: {sitemapCount}</strong>
              </div>
              <div>
                {sitemap}
              </div>
              </>
            )}
          </div>
        </div>
      </div>

    {showModal && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowModal(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Admin Note</h2>

            <div className='mt-10'>
            
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default Actions;