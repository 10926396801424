import {
  Box,
  Image,
  VStack,
  Heading,
  Text,
  Button,
  Flex,
} from '@chakra-ui/react';
import bg from './homeAssets/vendorBg.png';
import mobile from './homeAssets/mobile.png';
import mobile2 from './homeAssets/mobile2.png';
import phone1 from '../../assets/design/phone1.png';

const HeroVendor = () => {
  return (
    <>
      <Box
        mb='160px'
        position='relative'
        width='auto'
        height='auto'
        mx='100px'
        color='#FFF'>
        <Image src={bg} width='100%' height='100%' objectFit='cover' />

        <Flex
          position='absolute'
          top='50%'
          left='38%'
          transform='translate(-50%, -50%)'
          direction='row'
          align='center'
          spacing={8} // Adjust for spacing between the VStack and the new image
        >
          <VStack spacing={8} textAlign='left' align='start'>
            <Heading
              mb={0}
              fontFamily='poppins'
              fontSize='40px'
              fontWeight='700'
              lineHeight='40px'>
              Become A Vendor
            </Heading>
            <Text
              fontFamily='poppins'
              fontSize='16px'
              fontWeight='400'
              lineHeight='32px'>
              Do you have a software solution you'd like to list on LeadrPro?
              Signing up is 100% free. Only pay for demos you complete.
            </Text>
            <Button
              as='a'
              href='https://www.leadrpro.com/signup/seller'
              borderRadius='68px'
              bg='#FFF'
              color='#7107A8'
              fontFamily='poppins'
              padding='10px 40px'
              down
              _hover={{
                transform: 'scale(0.98)',
                bg: 'gray.200',
              }}>
              Schedule your onboarding
            </Button>
          </VStack>

          <Flex direction='row' position='relative' left='150px'>
            <Image
              src={mobile}
              alt='Description of the first mobile image'
              maxWidth='100%'
            />
            <Image
              src={mobile2}
              alt='Description of the second mobile image'
              maxWidth='100%'
              position='absolute'
              left='180px' // Adjust for the desired overlap
            />
          </Flex>
        </Flex>
      </Box>
    </>
  );
};

export default HeroVendor;
