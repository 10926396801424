import {
  Box,
  Heading,
  Center,
  HStack,
  VStack,
  Text,
  Button,
  Spacer,
  Image,
} from '@chakra-ui/react';

import apply from './homeAssets/apply.png';
import discover from './homeAssets/discover.png';
import getPaid from './homeAssets/getPaid.png';

const HowItWorks = () => {
  return (
    <>
      <Box bg='#D4F4F9' pt='90px' mt={10}>
        <Heading
          textAlign='center'
          fontFamily='poppins'
          fontSize='40px'
          fontWeight='700'
          lineHeight='70px'>
          How Does LeadrPro Work?
        </Heading>
        <section id='apply'>
          <Box mx='200px' mt='65px'>
            <Center>
              <HStack>
                <VStack align='start' pr='120px'>
                  <Heading
                    fontSize='32px'
                    fontFamily='poppins'
                    fontWeight='700'
                    lineHeight='38px'
                    pb='30px'>
                    Apply To Paid Demos
                  </Heading>
                  <Text
                    textAlign='left'
                    w='520px'
                    fontSize='18px'
                    fontFamily='poppins'
                    fontWeight='400'
                    lineHeight='32px'
                    color='#1D191F'
                    pb='30px'>
                    Browse our community of Software Vendors and easily apply to
                    paid demos. Applying is super easy and only takes about two
                    minutes per application.
                  </Text>
                  <HStack>
                    <Button
                      as='a'
                      href='https://www.leadrpro.com/software'
                      color='#FFF'
                      fontFamily='poppins'
                      w='250px'
                      h='50px'
                      p='10px 80px'
                      borderRadius='68px'
                      bg='#7107A8'
                      _hover={{
                        bg: 'purple.700',
                        transform: 'scale(0.98)',
                      }}>
                      Browse Software
                    </Button>
                    <Button
                      as='a'
                      href='https://www.leadrpro.com/signup'
                      bg='#FFF'
                      fontFamily='poppins'
                      color='#7107A8'
                      w='250px'
                      h='50px'
                      p='10px 80px'
                      borderRadius='68px'
                      border='1px solid #7107A8'
                      _hover={{
                        bg: 'grey.300',
                        transform: 'scale(0.98)',
                        border: '2.5px solid #7107A8',
                      }}>
                      Sign up free
                    </Button>
                  </HStack>
                </VStack>
                <Spacer />
                <Image src={apply} w='300px' />
              </HStack>
            </Center>
          </Box>
        </section>
        <section id='discover'>
          <Box mx='200px' mt='100px'>
            <Center>
              <HStack>
                <Image src={discover} w='300px' />
                <Spacer />

                <VStack align='start' pl='120px'>
                  <Heading
                    w='467px'
                    fontSize='32px'
                    fontWeight='700'
                    lineHeight='50px'
                    fontFamily='poppins'
                    pb='30px'>
                    Discover New Software
                  </Heading>
                  <Text
                    textAlign='left'
                    w='520px'
                    fontSize='18px'
                    fontWeight='400'
                    fontFamily='poppins'
                    lineHeight='32px'
                    color='#1D191F'
                    pb='30px'>
                    Discover new and innovative software solutions for your
                    business. Our product demos are hosted using an internal
                    video tool and usually take about 30 mins.
                  </Text>
                  <HStack>
                    <Button
                      as='a'
                      href='https://www.leadrpro.com/software'
                      color='#FFF'
                      w='250px'
                      h='50px'
                      p='10px 80px'
                      fontFamily='poppins'
                      borderRadius='68px'
                      bg='#7107A8'
                      _hover={{
                        bg: 'purple.700',
                        transform: 'scale(0.98)',
                      }}>
                      Browse Software
                    </Button>
                    <Button
                      as='a'
                      href='https://www.leadrpro.com/signup'
                      bg='#FFF'
                      color='#7107A8'
                      w='250px'
                      h='50px'
                      p='10px 80px'
                      borderRadius='68px'
                      fontFamily='poppins'
                      border='1px solid #7107A8'
                      _hover={{
                        bg: 'grey.300',
                        transform: 'scale(0.98)',
                        border: '2.5px solid #7107A8',
                      }}>
                      Sign up free
                    </Button>
                  </HStack>
                </VStack>
              </HStack>
            </Center>
          </Box>
        </section>
        <section id='getPaid'>
          <Box mx='200px' mt='70px' pb={28}>
            <Center>
              <HStack>
                <VStack align='start' pr='120px'>
                  <Heading
                    fontSize='32px'
                    fontFamily='poppins'
                    fontWeight='700'
                    lineHeight='50px'
                    pb='30px'>
                    Get Paid For Your Time
                  </Heading>
                  <Text
                    textAlign='left'
                    w='520px'
                    fontSize='18px'
                    fontWeight='400'
                    lineHeight='32px'
                    fontFamily='poppins'
                    color='#1D191F'
                    pb='30px'>
                    <Text fontWeight='700'>
                      We pay out $75 for every demo completed on LeadrPro!
                    </Text>
                    Once your demo is completed, you can cash out using PayPal,
                    Venmo, CashApp, Zelle, Amazon Gift Card or Visa.
                  </Text>
                  <HStack>
                    <Button
                      as='a'
                      href='https://www.leadrpro.com/software'
                      color='#FFF'
                      w='250px'
                      h='50px'
                      p='10px 80px'
                      fontFamily='poppins'
                      borderRadius='68px'
                      bg='#7107A8'
                      _hover={{
                        bg: 'purple.700',
                        transform: 'scale(0.98)',
                      }}>
                      Browse Software
                    </Button>
                    <Button
                      as='a'
                      href='https://www.leadrpro.com/signup'
                      bg='#FFF'
                      color='#7107A8'
                      w='250px'
                      fontFamily='poppins'
                      h='50px'
                      p='10px 80px'
                      borderRadius='68px'
                      border='1px solid #7107A8'
                      _hover={{
                        bg: 'grey.300',
                        transform: 'scale(0.98)',
                        border: '2.5px solid #7107A8',
                      }}>
                      Sign up free
                    </Button>
                  </HStack>
                </VStack>
                <Spacer />
                <Image src={getPaid} w='300px' />
              </HStack>
            </Center>
          </Box>
        </section>
      </Box>
    </>
  );
};

export default HowItWorks;
