import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { refresh } from '../../../services/auth.service';
import Spinner from '../../../components/layout/Spinner';
import FadeIn from '../../../utils/fadein';
import { FaCaretLeft, FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getPostFromSlug, createArticleView } from '../../../utils/api';
import './BlogDetails.css';
import ScrollToTop from "../../../components/layout/ScrollToTop";

function BlogDetails() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [postContent, setPostContent] = useState('');
  const [post, setPost] = useState(null);
  const [otherPosts, setOtherPosts] = useState([]);
  const [categories, setCategories] = useState([]);
  let { slug } = useParams();
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';
  let navigate = useNavigate();

  useEffect(() => {
    async function loadData(){
      let res = await getPostFromSlug(slug);

      if (res.success){
        setData(res.data.post);
        setPost(res.data);
        setOtherPosts(res.data.posts.edges);
        setCategories(res.data.post.categories.edges);
        setLoaded(true);
      }

      else{
       navigate('/404');
      }
    }

    loadData();
  }, [slug]);

  useEffect(() => {
    const handleMouseMove = () => {
      //setIsUserReal(true);
      // Log the user as real (e.g., send to your server or analytics)
      //console.log('User is real');
      //setRealCheckDone(true);

      createArticleView(slug);
      // Remove the event listener after detecting mouse movement
      window.removeEventListener('mousemove', handleMouseMove);
    };

    // Add the event listener for mouse movement
    window.addEventListener('mousemove', handleMouseMove);

    // Cleanup function to remove the event listener if the component unmounts
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  function removeHTMLTags(text) {
    // Create a temporary DOM element
    let tempElement = document.createElement('div');
    
    // Set the innerHTML of the temporary element to the provided text
    tempElement.innerHTML = text;
    
    // Retrieve the text content of the temporary element, which excludes HTML tags
    return tempElement.textContent || tempElement.innerText || '';
  }

  function generateMetaDescription(inputString = '') {
    const maxLength = 160;
    if (inputString.length <= maxLength) {
        return removeHTMLTags(inputString);
    } else {
        // Truncate the string to the maximum length minus 1 (for the ellipsis)
        // and ensure we don't cut off in the middle of a word.
        let trimmedString = inputString.substr(0, maxLength - 1);
        trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")));
        return removeHTMLTags(`${trimmedString}…`);
    }
  }

  return (
    <div className='blogdetails'>
      <div className='blogdetails__banner'>
        <span>
          Want to earn $75? Join LeadrPro and get paid for each demo meeting you book with one of our software vendors!
        </span>

        <span>
          <button onClick={() => navigate('/software-hub')} className='btn'>Get Started</button>
        </span>
      </div>
      <ScrollToTop />
      <Helmet>
        {loaded && (
          <link rel="canonical" href={`https://www.leadrpro.com/blog/${data.slug}`} />
        )}

        {loaded && (
          <title>{data.title}</title>
        )}

        <meta property="og:type" content="article" />

        {loaded && (
          <meta property="og:title" content={`${data.title}`} />
        )}

        {loaded && (
          <meta property="og:url" content={`https://www.leadrpro.com/blog/${data.slug}`} />
        )}

        {loaded && data.featuredImage && data.featuredImage.node.sourceUrl && (
            <meta property="og:image" content={data.featuredImage?.node.sourceUrl} />
        )}

          {loaded && data.excerpt && data.excerpt.length > 0 && (
            <meta property="og:description" content={generateMetaDescription(data.excerpt)} />
          )}

          {loaded && data.excerpt && data.excerpt.length > 0 && (
            <meta name='description' content={generateMetaDescription(data.excerpt)} />
          )}
      </Helmet>

      {loaded===false && (
        <>
        <div className='blogdetails__placeholder'>
          
          </div>

          <div className='blogdetails__placeholder'>
          
        </div>
        </>
      )}

      {loaded && (
        <div className='blogdetails__post'>
          <div className='blogdetails__post-header'>
            <div className='mb-10'>
              <Link className='link' to='/blog'><FaCaretLeft /> Back to articles</Link>
            </div>

            <h1 className='blogdetails__post-title'>
              {data.title}
            </h1>

            <div className='blogdetails__post-author'>
              {data.author && (
                <>
                  Written by: <span className='strong bold'>{data.author.node.name}</span>
                </>
              )}
            </div>

            <div className='blogdetails__post-date'>
              Published on: <time className='strong bold' dateTime={``}>{new Date(data.date).toLocaleDateString()}</time>
            </div>
          </div>

          <div className='blogdetails__post-image'>
            <img src={data.featuredImage?.node.sourceUrl} alt="Image of post" />
          </div>

          <p className='blogdetails__post-content' dangerouslySetInnerHTML={{ __html: data.content }}>
            
          </p>
        </div>
      )}

      {loaded && otherPosts && otherPosts.length > 0 && (
        <>
          <div className='blogdetails__other-posts'>
            {otherPosts.map(item => (
              <div className='blogdetails__other-post'>
                <div onClick={() => {
                  console.log('navigate', item.node.slug);
                  navigate(`/blog/${item.node.slug}`);
                }}>
                  <div className='blogdetails__post-image'>
                    <img src={item.node.featuredImage?.node.sourceUrl} alt="Image of post" />
                  </div>

                  <div className='blogdetails__other-post-title'>
                    {item.node.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default BlogDetails;