import {
  Box,
  Heading,
  Center,
  HStack,
  Image,
  Text,
  VStack,
  Flex,
  Avatar,
} from '@chakra-ui/react';
import article1 from './homeAssets/article1.png';
import article2 from './homeAssets/article2.png';
import article3 from './homeAssets/article3.png';
import jazmine from './homeAssets/jazmine.png';
import calendar from './homeAssets/calendarIcon.png';
import techcrunch from './homeAssets/techcrunch.png';

const ArticleMap = () => {
  return (
    <>
      <Box mt='60px' mb='160px' className='home__articles'>
        <Center>
          <VStack>
            <Heading
              pb='50px'
              color='#1D191F'
              fontFamily='poppins'
              fontSize='42px'
              fontWeight='700'
              lineHeight='50px'>
              In the News
            </Heading>
            <HStack>
              <section id='card1' className='home__article'>
                <Box
                  p='25px'
                  borderRadius='10px'
                  bg='#FFF'>
                  <Box
                    as='a'
                    href='https://ca.finance.yahoo.com/news/product-demo-startup-leadrpro-wants-170036939.html'>
                    <div className='home__article-image'>
                      <Image src={article1} mb='15px' />
                    </div>

                    <Flex direction='row' alignItems='center'>
                      <Image src={calendar} w='15px' mr='10px' mb='15px' />
                      <Text
                        pb='15px'
                        fontFamily='poppins'
                        fontSize='12px'
                        fontWeight='400'
                        lineHeight='12px'
                        letterSpacing='-0.12px'>
                        December 7, 2022
                      </Text>
                    </Flex>

                    <Heading
                      as='h4'
                      size='md'
                      fontFamily='poppins'
                      fontSize='16px'
                      fontWeight='600'
                      lineHeight='31px'
                      h='100px'
                      letterSpacing='-0.2px'>
                      Yahoo: Finance - Product demo startup LeadrPro wants to help companies pay for your attention
                    </Heading>
                    {/* <HStack pt='20px'>
                      <Avatar
                        src='https://ik.imagekit.io/slk77uytr/LeadrPro/LeadPro%20Logo/alternate-logo-1.png?updatedAt=1693074006049'
                        mr='4px'
                      />
                      <Heading
                        as='h4'
                        size='17px'
                        color='#7107A8'
                        fontWeight='600'
                        lineHeight='15px'
                        fontFamily='poppins'>
                        Jazmine Roxas
                      </Heading>
                    </HStack> */}
                  </Box>
                </Box>
              </section>

              <section id='card2' className='home__article'>
                <Box
                  p='25px'
                  borderRadius='10px'
                  bg='#FFF'>
                  <Box
                    as='a'
                    href='https://techcrunch.com/2022/12/07/product-demo-leadrpro/'
                    target='_blank'>
                    <div className='home__article-image'>
                      <Image src={article2} mb='15px' />
                    </div>

                    <Flex direction='row' alignItems='center'>
                      <Image src={calendar} w='15px' mr='10px' mb='15px' />
                      <Text
                        pb='15px'
                        fontFamily='poppins'
                        fontSize='12px'
                        fontWeight='400'
                        lineHeight='12px'
                        letterSpacing='-0.12px'>
                        December 7, 2022
                      </Text>
                    </Flex>

                    <Heading
                      as='h4'
                      size='md'
                      fontFamily='poppins'
                      fontSize='16px'
                      fontWeight='600'
                      lineHeight='31px'
                      h='100px'
                      letterSpacing='-0.2px'>
                      TechCrunch: Product Demo Startup LeadrPro Wants To...
                    </Heading>
                    {/* <HStack pt='20px'>
                      <Avatar src={techcrunch} mr='4px' />
                      <Heading
                        as='h4'
                        size='17px'
                        color='#7107A8'
                        fontWeight='600'
                        lineHeight='15px'
                        fontFamily='poppins'>
                        Christine Hall
                      </Heading>
                    </HStack> */}
                  </Box>
                </Box>
              </section>

              <section id='card3' className='home__article'>
                <Box
                  p='25px'
                  borderRadius='10px'
                  bg='#FFF'>
                  <Box
                    as='a'
                    href='https://www.ibtimes.com/10-fastest-growing-tech-companies-3680296'>
                    <div className='home__article-image'>
                    <Image src={article3} mb='15px' />
                    </div>

                    <Flex direction='row' alignItems='center'>
                      <Image src={calendar} w='15px' mr='10px' mb='15px' />
                      <Text
                        pb='15px'
                        fontFamily='poppins'
                        fontSize='12px'
                        fontWeight='400'
                        lineHeight='12px'
                        letterSpacing='-0.12px'>
                        March 24, 2023
                      </Text>
                    </Flex>

                    <Heading
                      as='h4'
                      size='md'
                      fontFamily='poppins'
                      fontSize='16px'
                      fontWeight='600'
                      lineHeight='31px'
                      h='100px'
                      letterSpacing='-0.2px'>
                      10 Of The Fastest Growing Tech Companies
                    </Heading>
                    {/* <HStack pt='20px'>
                      <Avatar
                        src='https://ik.imagekit.io/slk77uytr/LeadrPro/LeadPro%20Logo/alternate-logo-1.png?updatedAt=1693074006049'
                        mr='4px'
                      />
                      <Heading
                        as='h4'
                        size='17px'
                        color='#7107A8'
                        fontWeight='600'
                        lineHeight='15px'
                        fontFamily='poppins'>
                        Jazmine Roxas
                      </Heading>
                    </HStack> */}
                  </Box>
                </Box>
              </section>
            </HStack>
          </VStack>
        </Center>
      </Box>
    </>
  );
};

export default ArticleMap;
