import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { refresh } from '../../services/auth.service';
import Spinner from '../../components/layout/Spinner';
import FadeIn from '../../utils/fadein';
import { FaEllipsisV, FaTimes } from 'react-icons/fa';
import { getPointActivity, createPointActivity, updatePointActivity } from '../../utils/api/admin';
import './PointActivity.css';

function PointActivity() {
  const [loaded, setLoaded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [pageBreakdown, setPageBreakdown] = useState([]);
  const [data, setData] = useState([]);
  const [showSaveBanner, setShowSaveBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [pointsEarned, setPointsEarned] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [editName, setEditName] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editPointsEarned, setEditPointsEarned] = useState('');
  const [costPerCredit, setCostPerCredit] = useState(.25);
  const [totalPoints, setTotalPoints] = useState(0);
  const imageUrl = process.env.REACT_APP_IMAGE_URL;
  const baseImageUrl = process.env.REACT_APP_BASE_IMAGE_URL;
  const screenshotUrl = process.env.REACT_APP_BASE_IMAGE_URL + 'companyscreenshots/';

  async function loadData(){
    let res = await getPointActivity();

    if (res.success){
      setData(res.data);
      setLoaded(true);

      let _total = 0;

      for (let i = 0; i < res.data.length; i++){
        _total += parseInt(res.data[i].points_earned);
      }

      setTotalPoints(_total);
    }
  }

  useEffect(() => {
    async function loadData(){
      let res = await getPointActivity();

      if (res.success){
        setData(res.data);
        setLoaded(true);

        let _total = 0;

        for (let i = 0; i < res.data.length; i++){
          _total += parseInt(res.data[i].points_earned);
        }
  
        setTotalPoints(_total);
      }
    }

    loadData();
  }, []);

  async function handleCreate(){
    let res = await createPointActivity(name, description, pointsEarned);

    if (res.success){
      loadData();

      setName('');
      setDescription('');
      setPointsEarned('');
    }
  }

  function loadSaveBanner(){
    setShowSaveBanner(true);
    const timer = setTimeout(() => {
      setShowSaveBanner(false);
    }, 3000);
    return () => clearTimeout(timer);
  }

  async function handleEdit(id, item){
    setSelectedId(id);
    setEditName(item.name);
    setEditDescription(item.description);
    setEditPointsEarned(item.points_earned);
    setShowEdit(true);
  }

  async function handleUpdate(){
    let res = await updatePointActivity(selectedId, editName, editDescription, editPointsEarned);

    if (res.success){
      setShowEdit(false);
      loadData();
    }

    else{
      alert("Update failed");
    }
  }

  return (
    <>
      <Helmet>
        <title>LeadrPro - Discover new software</title>
      </Helmet>

      <h1>Point Activity</h1>

      <p className='mt-10 mb-10'>
        <strong>Site activities that create user points.</strong>
      </p>

      <div className='admindashboard__cards'>
            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Cost Per Credit</strong></div>

              <div className='mt-10'>
                <input type='text' className='input' placeholder='Cost per credit...' value={costPerCredit} onChange={(e) => setCostPerCredit(e.target.value)} />
              </div>
            </div>

            <div className='admindashboard__card'>
              <div className='admindashboard__card-title'><strong>Total Dollar Value</strong></div>

              <div className='mt-10' style={{fontSize: '25px', fontWeight: 'bold'}}>
                {(totalPoints * costPerCredit).toFixed(2)}
              </div>
            </div>
          </div>

      <div className='mt-10'>
        <span className='btn' onClick={() => setShowAdd(!showAdd)}>+ Add Activity</span>
      </div>

      {showAdd && (
      <div className='admindashboard__form'>
        <div>
          <label>Name</label>
          <div>
            <input type='text' placeholder='' className='input' value={name} onChange={(e) => setName(e.target.value)} />
          </div>
        </div>

        <div className='mt-10'>
          <label>Description</label>
          <div>
            <textarea className='input textarea' placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)}></textarea>
          </div>
        </div>

        <div className='mt-10'>
          <label>Points Earned</label>
          <div>
            <input type='text' className='input' placeholder='Points earned' value={pointsEarned} onChange={(e) => setPointsEarned(e.target.value)} />
          </div>
        </div>

        <div className='mt-20'>
          <button className='btn' onClick={() => handleCreate()}>Add Activity</button>
        </div>
      </div>
      )}

      {loaded===false && (
        <Spinner />
      )}

      {loaded && (
         <div>
          <table className='admindashboard__table table'>
            <tr>
              <th>Name</th>
              <th>Description</th>
              <th>Points Earned</th>
              <th>Distributed</th>
              <th>Dollar Cost</th>
              <th>Total Spent</th>
              <th></th>
            </tr>

            {data.map((item, index) => (
              <tr key={index}>
                <td>
                  {item.name}
                </td>

                <td>
                  {item.description}
                </td>

                <td>
                  +{item.points_earned}
                </td>

                <td>
                  {item.points_distributed}
                </td>

                <td>
                  <span style={{fontWeight: 'bold'}}>
                    ${(costPerCredit * parseInt(item.points_earned)).toFixed(2)}
                  </span>
                </td>

                <td>
                  <span style={{fontWeight: 'bold'}}>
                    ${((costPerCredit * parseInt(item.points_earned)) * parseInt(item.points_distributed)).toFixed(2)}
                  </span>
                </td>

                <td>
                  <button className='btn' onClick={() => handleEdit(item.point_activity_id, item)}>Edit</button>
                </td>
              </tr>
            ))}
          </table>
       </div>
      )}

    {showEdit && (
        <>
          <div className='overlay'></div>
          <div className='modal'>
            <div className='modal__close' onClick={() => setShowEdit(false)}>
              <FaTimes />
            </div>

            <h2 className='modal__h2'>Edit</h2>

            <div className='mt-10'>
              <div>
                <label>Name</label>
                <input type='text' className='input' value={editName} onChange={(e) => setEditName(e.target.value)}></input>
              </div>

              <div>
                <label>Description</label>

                <textarea className='input textarea' value={editDescription} onChange={(e) => setEditDescription(e.target.value)}></textarea>
              </div>

              <div>
                <label>Points Earned</label>
                <input type='text' className='input' value={editPointsEarned} onChange={(e) => setEditPointsEarned(e.target.value)} />
              </div>

              <div className='mt-20'>
                <button className='btn' onClick={() => handleUpdate()}>Update</button>
              </div>
            </div>
          </div>
        </>
      )}

      {showSaveBanner && (
        <FadeIn delay={150} duration={300}>
          <div className='profileinfo__saved-banner'>
            Profile updated successfully!
          </div>
        </FadeIn>
      )}
    </>
  );
}

export default PointActivity;