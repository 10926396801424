import './PortfolioHome.css';
import { useState, useEffect } from "react";
import { useNavigate, Link } from 'react-router-dom';
import {Helmet} from "react-helmet";
import { isAuthed, getCurrentUser, refresh } from '../../services/auth.service';
import { useLocation } from 'react-router-dom';
import { saveSiteFeedback, getCompanyProfileContent } from '../../utils/api';
import ScrollToTop from '../../components/layout/ScrollToTop';

function PortfolioHome() {
    const search = useLocation().search;
    const [completed, setCompleted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [feedbackText, setFeedbackText] = useState('');
    const [content, setContent] = useState([]);

    useEffect(() => {

      async function loadContent(){
        let res = await getCompanyProfileContent(1);

        if (res.success){
          setContent(res.data);
        }

        else{
          alert('failed to load content');
        }
      }

      loadContent(); // Call the function to load content
      document.title = "LeadrPro - The Portfolio";
    }, []);

    return (
        <div className="flex flex-col items-center portfoliohome">
          <ScrollToTop/>
          
          <Helmet>
            <meta name="description" content="" />
          </Helmet>

          <header className="text-center my-8 portfoliohome__header">
            <h1 className="text-4xl font-bold portfoliohome__h1">The Portfolio</h1>
            <p className="text-lg mt-4">Explore insights on people, companies, and business data.</p>
          </header>

          <div className="flex w-full justify-start px-8 gap-8">
            <aside className="w-1/4">
              <div className="mb-4">
                <h2 className="font-semibold">More Resources</h2>
                <ul>
                  <li className="my-2"><a href="/blog" className="text-blue-500">Blog</a></li>
                </ul>
              </div>
            </aside>

            <main className="w-1/2">
              <div className="mb-4">
                <input type="text" placeholder="Search articles..." className="w-full p-2 border border-gray-300 rounded"/>
              </div>
              <div className="space-y-4">
                {content.map((article, index) => (
                  <article key={index} className="p-4 border-b border-gray-300">
                    <h2 className="text-xl font-semibold">
                      <Link to={`/portfolio/${article.url_slug}`} className="text-black">
                        {article.title}
                      </Link>
                    </h2>

                    <p className="text-gray-600">{article.meta_description}</p>

                    <p className="text-gray-500 text-sm mt-5">{new Date(article.date_published).toLocaleDateString()}</p>
                  </article>
                ))}
              </div>
            </main>

            {/* <aside className="w-1/4">
              <div className="mb-4">
                <h2 className="font-semibold">Community</h2>
                <ul>
                  <li className="my-2"><a href="#" className="text-blue-500">Join Slack</a></li>
                  <li className="my-2"><a href="#" className="text-blue-500">Subscribe on YouTube</a></li>
                  <li className="my-2"><a href="#" className="text-blue-500">Follow on LinkedIn</a></li>
                </ul>
              </div>
            </aside> */}
          </div>
        </div>
    );
}

export default PortfolioHome;